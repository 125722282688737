import * as React from 'react';
import { ClientCard } from '../App/ClientCard';
import { TabPanel } from "./TabPanel";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import AppConfig from '../../constants/AppConfig';
import { WarrantyList } from './WarrantyList';
import { StoreContext } from '../../store/StoreProvider';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { WarrantyActivationsList } from './WarrantyActivationsList';
import { types } from "../../store/Dialog";
import { getI18N } from '../../i18n'

interface WarrantyProps { }

const { colors: { primary, secondary, success, danger }, types: { seller, partner } } = AppConfig

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const ActivationComponent = (props: WarrantyProps) => {
  const [store, dispatch] = React.useContext(StoreContext);

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  let titleTabSearch = "";

  if (store?.userAppCorp) {
    titleTabSearch = "UNIDAD";
  } else {
    switch (store?.user?.Country) {
      case "PE":
      case "NAC":
        titleTabSearch = "DNI / RUC";
        break;
      case "VE":
        titleTabSearch = "CI / RIF";
        break;
      case "CL":
        titleTabSearch = "RUT";
        break;
      case "EC":
        titleTabSearch = "CI / RUC / CE";
        break;
      case "DO":
        titleTabSearch = "CI / RNC";
        break;
      case "BO":
        titleTabSearch = "CI / NIT";
        break;
      case "TT":
        titleTabSearch = "NIC / TIN";
        break;
    }
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch({ type: types.change_warranty_tab, payload: newValue });
  };

  return (
    <div className="warranty-container">
      {/* <Box sx={{ pl: 1, pr: 1 }}>
        <Grid container spacing={1}>
          <Grid className="container-client" item xs={8}>
            <ClientCard />
          </Grid>
        </Grid>
      </Box> */}
      <Box sx={{ flexGrow: 5, pl: 1, pr: 1 }}>
        <div className="battery-warranty-list-container">
          <Tabs
            value={store.active_warranty_tab}
            onChange={handleChange}
            centered
            className="bmg-container-tabs"
          >
            <Tab className="black-text-color bmg-custom-tab" label={i18n.DATE} {...a11yProps(0)} />
            <Tab className="black-text-color bmg-custom-tab" label={i18n.PLATE} {...a11yProps(1)} />
            <Tab className="black-text-color bmg-custom-tab" label={titleTabSearch} {...a11yProps(2)} />
            {store?.userAppCorp && <Tab className="black-text-color bmg-custom-tab" label={i18n.MINICODE} {...a11yProps(3)} />}
          </Tabs>
          <TabPanel value={store.active_warranty_tab} index={0} padding={true}>
            <WarrantyActivationsList typeTab='0' />
          </TabPanel>
          <TabPanel value={store.active_warranty_tab} index={1} padding={true}>
            <WarrantyActivationsList typeTab='1' />
          </TabPanel>
          <TabPanel value={store.active_warranty_tab} index={2} padding={true}>
            <WarrantyActivationsList typeTab='2' />
          </TabPanel>
          {store?.userAppCorp && <TabPanel value={store.active_warranty_tab} index={3} padding={true}>
            <WarrantyActivationsList typeTab='3' />
          </TabPanel>}
          

        </div>
      </Box>
    </div>
  );
};

