import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
// import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import { Typography } from "@material-ui/core";
// import CheckIcon from "@mui/icons-material/Check";
import { Maintenance } from "./Maintenance";
import { StoreContext } from "../../store/StoreProvider";
import { Intensivo, IntensivoTaxi, MultiInput, Particular, Pesado, Publico } from "../MultiInput";
import Loading from "../Loading";
import { Link } from '@mui/material';

import { AddressModal } from "./AddressModal";
import { types } from "../../store/Warranty";
import {
  WarrantyCard, WarrantyCardDetail,
  CorpWarrantyCard, getCorpWarrantyMinicod,
  // CorpMarcaModeloVehiculos, CorpClientAddress,
  getCorpFrecuenciaGarantia, CorpGetWarrantyCardsMaintenanceById
} from "../../store/sagas/Warranty";
import { Bottom } from "../App/Bottom";
import { Ecuador } from "./countries/ecuador";
import { Chile } from "./countries/chile";
import { Venezuela } from "./countries/venezuela";
import { Alert } from "../Alert";
// import { DisplaySelects } from "./DisplaySelects";
import LoadingOverlay from "../LoadingOverlay";
import { ReplublicaDominicana } from "./countries/republicaDominicana";
import { bmgSendMessage, clientPointsAddExt, fileUpload } from "../../store/sagas/Clients";

import AppConfig from '../../constants/AppConfig';
import { useDebounce } from "../../hooks/useDebounce";

import { SuccessModal } from "../Home/SuccessModal";
import { Bolivia } from "./countries/bolivia";
// import { domain } from "process";
import { getI18N } from '../../i18n'
import { TrinidadYTobago } from "./countries/TrinidadYTobago";

import { Camera } from "../App/Camera";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

var ACTIVE_UPDATE = "";

function DisplaySelects(props: any) {
  const [store, dispatch] = React.useContext(StoreContext);
  const { battery, batteryCorpList } = store;
  let dateLocal = battery?.FechaVenta?.indexOf("T") !== -1 ? battery?.FechaVenta : moment(battery?.FechaVenta, "DD-MM-yyyy").format("LLLL");
  const [value, setValue] = React.useState<Date | null>(new Date(dateLocal));
  const [Garantia, setGarantia] = React.useState(
    battery?.Garantia === "0" ? "" : battery?.Garantia
  );
  const [Mantenimiento, setMantenimiento] = React.useState(
    battery?.Mantenimiento === "0" ? "" : battery?.Mantenimiento
  );

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  // const [openInfoBattery, setOpenInfoBattery] = React.useState(false);

  const handleChangeSelect = (event: SelectChangeEvent) => {
    setGarantia(event.target.value);
  };

  const handleChangeDate = (newValue: Date | null) => {
    setValue(newValue);
  };

  const setWarrantyValue = () => {
    return props?.customWarranty || Garantia;
  };

  const setRevValue = () => {
    return props?.customRev || Mantenimiento;
  };

  const BatteryInfo = (propBattery: any) => {

    return (
      <div className="battery-container battery-container-info">
        <Box sx={{ flexGrow: 1 }} className="content-battery-code">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div className="battery-detail-container battery-detail-name">
                <Typography variant="h6" align="center" className="uppercase">
                  {propBattery?.battery?.IdMiniCod ?? propBattery?.battery?.Minicodigo}
                </Typography>
                <Typography
                  variant="caption"
                  align="center"
                  className="uppercase"
                >
                  {/* Mod: {battery?.Modelo} | Letra: {battery?.Letra} */}
                </Typography>
              </div>
            </Grid>

            <Grid item xs={3}>
              <div className="battery-detail-container">
                <span className="subtitle">{i18n.MODEL}</span>
                <span className="title">{propBattery?.battery?.Modelo}</span>

              </div>
            </Grid>

            <Grid item xs={2}>
              <div className="battery-detail-container">
                <span className="subtitle">{i18n.VOLTAGE}</span>
                <span className="title">{propBattery?.battery?.Voltaje}V</span>

              </div>
            </Grid>
            <Grid item xs={2}>
              <div className="battery-detail-container">
                <span className="subtitle">CN</span>
                <span className="title">{propBattery?.battery?.CapacidadNominal ?? propBattery?.battery?.CN}Ah</span>

              </div>
            </Grid>
            <Grid item xs={2}>
              <div className="battery-detail-container">
                <span className="subtitle">CCA</span>
                <span className="title">
                  {propBattery?.battery?.CorrienteArranqueEnFrio ?? propBattery?.battery?.CCA}A
                </span>

              </div>
            </Grid>
            <Grid item xs={3}>
              <div className="battery-detail-container">
                <span className="subtitle">{i18n.DENSITY}</span>
                <span className="title">{propBattery?.battery?.Densidad || 0}</span>

              </div>
            </Grid>
            {/* <Grid item xs={2}>
              <div className="battery-detail-container">
                <CheckIcon color="success" />
              </div>
            </Grid> */}
          </Grid>
        </Box>
      </div>
    );
  };

  // const setStateOpenInfo = () => {
  //   setOpenInfoBattery(!openInfoBattery)
  //   props.setOpenInfoBattery(!openInfoBattery)
  // }

  return (
    <details
      className={`details active success`}
      // open={props?.openInfoBattery}
      // onClick={setStateOpenInfo}
      id="bmgInfoBattery"
    >
      <summary>
        <strong style={{ color: "#000" }}>{i18n.BATTERY_INFORMATION}
        </strong>
        <div className="arrow-container d-flex">
          <ArrowDropDownIcon />
        </div>
      </summary>

      <div className="details-body">

        {store?.userAppCorp && batteryCorpList?.DatosBaterias?.map((item: any, index: any) => {
          return (
            <BatteryInfo key={`BTI${index}`} battery={item} />
          );
        })}

        {!store?.userAppCorp && (
          <BatteryInfo battery={battery} />
        )}

        {!store?.userAppCorp && (
          <div className="input-container container-bg">
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <MobileDatePicker
                      label={i18n.DATE}
                      inputFormat="DD-MM-yyyy"
                      value={value}
                      onChange={handleChangeDate}
                      renderInput={(params: any) => (
                        <TextField {...params} required={true} />
                      )}
                      disabled={true}
                      data-keyword={"FecUpd"}
                      data-parent={"FecUpd"}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel id="select-garantia-label">
                      {i18n.WARRANTY_PERIOD} ({i18n.MONTHS})
                    </InputLabel>
                    <Select
                      // required={true}
                      className="input-battery"
                      labelId="select-garantia-label"
                      id="select-garantia"
                      value={setWarrantyValue()}
                      label="Garantia"
                      onChange={(event) => {
                        props.setCustomWarranty(event.target.value);
                        setGarantia(event.target.value);
                      }}
                      disabled={Number(battery?.Id) === -1 ? false : true}
                      data-keyword={"Garantia"}
                      data-parent={"Garantia"}
                    >
                      {/* <option aria-label="None" value="" /> */}
                      <MenuItem value={6}>6</MenuItem>
                      <MenuItem value={9}>9</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={12}>12</MenuItem>
                      <MenuItem value={15}>15</MenuItem>
                      <MenuItem value={18}>18</MenuItem>
                      <MenuItem value={24}>24</MenuItem>
                      <MenuItem value={36}>36</MenuItem>
                      {/* <MenuItem value={6}>6</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={12}>12</MenuItem>
                <MenuItem value={18}>18</MenuItem>
                {store?.battery?.CodigoTipoBateria !== 1 && (
                  <MenuItem value={24}>24</MenuItem>
                )} */}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel id="select-mantenimiento-label">
                      {i18n.REVIEW_MAINTENANCE_PERIOD} ({i18n.MONTHS})
                    </InputLabel>
                    <Select
                      className="input-battery"
                      labelId="select-mantenimiento-label"
                      id="select-mantenimiento"
                      value={setRevValue()}
                      label="Mantenimiento"
                      onChange={(event) => {
                        props.setCustomRev(event.target.value);
                        setMantenimiento(event.target.value);
                      }}
                      disabled={Number(battery?.Id) === -1 ? false : true}
                      data-keyword={"Mantenimiento"}
                      data-parent={"Mantenimiento"}
                    >
                      {/* <option aria-label="None" value="" /> */}
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={6}>6</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </div>
        )}

      </div>

    </details>

  );
}

const UserData = () => {
  const [store, dispatch] = React.useContext(StoreContext);
  const [showUpd, setUpd] = React.useState(false);
  const { battery } = store;

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  let num = 0;
  for (let index = 0; index < battery?.DatosUsuario?.length; index++) {
    if (!!battery?.DatosUsuario[index].Valor) num++;
  }

  const updateRequest = () => {
    ACTIVE_UPDATE = "USER";
    let btn: any = document?.getElementById("btn-submit");
    btn?.click();
  };

  // (Number(battery?.Id) === -1 ? "success" : "disabledcard")
  // num !== battery?.DatosUsuario.length

  return (
    <details
      className={`details active ${num !== battery?.DatosUsuario?.length ? "" : "success"}`}
    >
      <summary>
        <strong style={{ color: "#000" }}>{i18n.CLIENT_DATA}</strong>
        <div className="arrow-container d-flex">
          {/* <progress max={battery?.DatosUsuario.length} value={num}></progress> */}
          <span
            // className={
            //   num !== battery?.DatosUsuario.length ? "primary" : "success"
            // }
            style={{ marginLeft: "10px" }}
          >{`(${num}/${battery?.DatosUsuario?.length})`}</span>
          <ArrowDropDownIcon />
        </div>
      </summary>
      <div className="details-body">
        {battery?.DatosUsuario?.map((item: any, i: number) => {
          let placeholder = item?.PlaceHolder;
          if (!!item?.FlgObligatorio) placeholder = `${placeholder} (*)`;
          let update = Number(battery?.Id) !== -1 ? true : false;
          if (!!battery?.TienePreTarjeta) {
            update = true;
          }

          // item.DescDet.toLocaleLowerCase() === "Documento de identificación".toLocaleLowerCase()

          if (item.IdDet === AppConfig.userItemsType.DOCUMENT) {
            item = {
              ...item,
              Tipo: "TXT",
            };
          }

          return (
            <MultiInput
              key={i}
              data={item}
              requred={item?.FlgObligatorio}
              placeholder={placeholder}
              className="input-battery"
              parent="DatosUsuario"
              disabled={update}
              keyword={JSON.stringify(item)}
              update={update}
              onChange={() => {
                if (!showUpd) setUpd(true);
              }}
              callback={updateRequest}
            />
          );
        })}
      </div>
      {!!showUpd && (
        <Button
          fullWidth
          variant="contained"
          className="btn-success"
          color="secondary"
          onClick={updateRequest}
        >
          <strong>{i18n.UPDATE}</strong>
        </Button>
      )}
    </details>
  );
};

const CarData = (props: any) => {
  const [store, dispatch] = React.useContext(StoreContext);
  const { battery, batteryCorpList } = store;

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  const batteryData = (!store?.userAppCorp) ? battery : batteryCorpList;

  let num = 0;
  for (let index = 0; index < batteryData?.DatosVehiculo?.length; index++) {
    if (!!batteryData?.DatosVehiculo[index].Valor) num++;
  }

  if ((store?.userAppCorp && batteryCorpList?.MiniCodigos !== null)) {
    num += 2;
  }

  const [showUpd, setUpd] = React.useState(false);
  const updateRequest = () => {
    ACTIVE_UPDATE = "CAR";
    let btn: any = document?.getElementById("btn-submit");
    btn?.click();
  };

  const [comb, setComb] = React.useState();
  const [typeUse, setTU] = React.useState();

  const [Mantenimiento, setMantenimiento] = React.useState(
    batteryCorpList?.FrecManto === "0" ? "" : batteryCorpList?.FrecManto
  );

  const [TiempoGarantia, setTiempoGarantia] = React.useState(
    batteryCorpList?.TiempoGar === "0" ? "" : batteryCorpList?.TiempoGar
  );


  // corporativo
  const [formState, setFormState] = React.useState({
    periodoRevision: '',
    tiempoGarantia: '',
  });

  const handleInputChangeCorp = (e: any) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  /*React.useEffect(() => {
    // console.log(comb, typeUse)
    const setCusWar = () => {
      // 6,9,12,18
      // if (store?.battery?.CodigoTipoBateria === 1 || store?.battery?.CodigoTipoBateria === 22) {

      switch (store?.user?.Country) {
        case "PE":
        case "NAC":
          if ((comb === "GASOLINA" || comb === "DIESEL") && typeUse === IntensivoTaxi) {
            return 9;
          }
          if ((comb === "GASOLINA" || comb === "DIESEL") && typeUse === Particular) {
            return 18;
          }
          if ((comb === "GASOLINA" || comb === "DIESEL") && typeUse === Pesado) {
            return 12;
          }
          if ((comb === "DUAL/GLP" || comb === "DUAL/GNV") && typeUse === Intensivo) {
            return 6;
          }
          break;
        case "EC":
          if ((comb === "GASOLINA" || comb === "DIESEL") && typeUse === Publico) {
            return 10;
          }
          // if ((comb === "GASOLINA" || comb === "DIESEL") && typeUse === IntensivoTaxi) {
          //   return 9;
          // }
          if ((comb === "GASOLINA" || comb === "DIESEL") && typeUse === Particular) {
            // return 18;
            return 15;
          }
          if ((comb === "GASOLINA" || comb === "DIESEL") && typeUse === Pesado) {
            return 12;
          }

          break;
        case "VE":
          if ((comb === "GASOLINA" || comb === "DIESEL") && typeUse === Publico) {
            // return 9;
            return 12;
          }
          if ((comb === "GASOLINA" || comb === "DIESEL") && typeUse === Particular) {
            // return 18;
            return 24;
          }
          if ((comb === "GASOLINA" || comb === "DIESEL") && typeUse === Pesado) {
            return 12;
          }
          break;
        case "CL":
          // if ((comb === "BENCINA" || comb === "DIESEL") && typeUse === IntensivoTaxi) {
          //   return 9;
          // }
          if ((comb === "GASOLINA" || comb === "DIESEL") && typeUse === Publico) {
            return 12;
          }
          if ((comb === "GASOLINA" || comb === "DIESEL") && typeUse === Particular) {
            return 24;
          }
          if ((comb === "GASOLINA" || comb === "DIESEL") && typeUse === Pesado) {
            return 12;
          }
          // if ((comb === "BENCINA" || comb === "DIESEL") && typeUse === Particular) {
          //   return 18;
          // }
          // if ((comb === "BENCINA" || comb === "DIESEL") && typeUse === Pesado) {
          //   return 12;
          // }
          if ((comb === "GLP" || comb === "GNV") && typeUse === Intensivo) {
            return 6;
          }
          // if ((comb === "BENCINA" || comb === "DIESEL") && typeUse === Particular) {
          //   return 24;
          // }
          break;
        case "DO":
          if ((comb === "GASOLINA" || comb === "DIESEL") && typeUse === Publico) {
            return 12;
          }
          if ((comb === "GASOLINA" || comb === "DIESEL") && typeUse === Particular) {
            return 24;
          }
          if ((comb === "GASOLINA" || comb === "DIESEL") && typeUse === Pesado) {
            return 12;
          }

          break;
        default:
          break;
      }

      return false;
      // } else {
      //   if (comb === "GASOLINA" && typeUse === IntensivoTaxi) {
      //     return 6;
      //   }
      //   if (comb === "GASOLINA" && typeUse === Particular) {
      //     return 24;
      //   }
      //   if (comb === "GASOLINA" && typeUse === Pesado) {
      //     return 18;
      //   }
      //   return false;
      // }
    };

    const setCusRev = () => {
      // 2,6,3,1

      // if (store?.battery?.CodigoTipoBateria === 1 || store?.battery?.CodigoTipoBateria === 22) {

      switch (store?.user?.Country) {
        case "PE":
        case "NAC":
          if ((comb === "GASOLINA" || comb === "DIESEL") && typeUse === IntensivoTaxi) {
            return 2;
          }
          if ((comb === "GASOLINA" || comb === "DIESEL") && typeUse === Particular) {
            return 6;
          }
          if ((comb === "GASOLINA" || comb === "DIESEL") && typeUse === Pesado) {
            return 3;
          }
          if ((comb === "DUAL/GLP" || comb === "DUAL/GNV") && typeUse === Intensivo) {
            return 1;
          }
          break;
        case "EC":
          // if ((comb === "GASOLINA" || comb === "DIESEL") && typeUse === IntensivoTaxi) {
          //   return 2;
          // }
          if ((comb === "GASOLINA" || comb === "DIESEL") && typeUse === Publico) {
            return 2;
          }
          if ((comb === "GASOLINA" || comb === "DIESEL") && typeUse === Particular) {
            return 6;
          }
          if ((comb === "GASOLINA" || comb === "DIESEL") && typeUse === Pesado) {
            return 3;
          }

          break;
        case "VE":
          if ((comb === "GASOLINA" || comb === "DIESEL") && typeUse === Publico) {
            return 2;
          }
          if ((comb === "GASOLINA" || comb === "DIESEL") && typeUse === Particular) {
            return 6;
          }
          if ((comb === "GASOLINA" || comb === "DIESEL") && typeUse === Pesado) {
            return 3;
          }
          break;
        case "CL":
          // if ((comb === "BENCINA" || comb === "DIESEL") && typeUse === IntensivoTaxi) {
          //   return 2;
          // }
          // if ((comb === "BENCINA" || comb === "DIESEL") && typeUse === Particular) {
          //   return 6;
          // }
          // if ((comb === "BENCINA" || comb === "DIESEL") && typeUse === Pesado) {
          //   return 3;
          // }
          // if ((comb === "GLP" || comb === "GNV") && typeUse === Intensivo) {
          //   return 1;
          // }
          if ((comb === "GASOLINA" || comb === "DIESEL") && typeUse === Publico) {
            return 2;
          }
          if ((comb === "GASOLINA" || comb === "DIESEL") && typeUse === Particular) {
            return 6;
          }
          if ((comb === "GASOLINA" || comb === "DIESEL") && typeUse === Pesado) {
            return 3;
          }
          if ((comb === "GLP" || comb === "GNV") && typeUse === Intensivo) {
            return 2;
          }
          // if ((comb === "BENCINA" || comb === "DIESEL") && typeUse === Particular) {
          //   return 6;
          // }
          break;
        case "DO":
          if ((comb === "GASOLINA" || comb === "DIESEL") && typeUse === Publico) {
            return 2;
          }
          if ((comb === "GASOLINA" || comb === "DIESEL") && typeUse === Particular) {
            return 6;
          }
          if ((comb === "GASOLINA" || comb === "DIESEL") && typeUse === Pesado) {
            return 3;
          }

          break;
        default:
          break;
      }


      return false;
      // } else {
      //   if (comb === "GASOLINA" && typeUse === IntensivoTaxi) {
      //     return 1;
      //   }
      //   if (comb === "GASOLINA" && typeUse === Particular) {
      //     return 6;
      //   }
      //   if (comb === "GASOLINA" && typeUse === Pesado) {
      //     return 3;
      //   }
      //   return false;
      // }
    };
    if (!!comb && !!typeUse) {
      let num: any = setCusWar();
      let numRev = setCusRev();
      props.setCustomWarranty(num);
      props.setCustomRev(numRev);
    }
  }, [comb, typeUse, props, store]);*/

  // console.log(batteryData?.DatosVehiculo, batteryData)

  const numLengthVehiculoData = (store?.userAppCorp && batteryCorpList?.MiniCodigos !== null) ? (batteryData?.DatosVehiculo?.length + 2) : batteryData?.DatosVehiculo?.length;

  // ${((!store?.userAppCorp && Number(batteryData?.Id) === -1) || (store?.userAppCorp && batteryCorpList?.MiniCodigos === null)) ? "" : "success"}

  return (
    <details
      className={`details active ${numLengthVehiculoData === num ? "success" : ""}`}
      id="bmgDataVehicle"
    >
      <summary key={Math.random()}>
        <strong style={{ color: "#000" }}>{i18n.VEHICLE_DATA}</strong>
        <div className="arrow-container d-flex">
          {/* <progress max={battery?.DatosVehiculo.length} value={num}></progress> */}
          <span
            // className={
            //   num !== battery?.DatosVehiculo.length ? "primary" : "success"
            // }
            style={{ marginLeft: "10px" }}
          >{`(${num}/${numLengthVehiculoData})`}</span>
          <ArrowDropDownIcon />
        </div>
      </summary>

      <div className="details-body">
        {batteryData?.DatosVehiculo?.map((item: any, i: number) => {

          let placeholder = item?.PlaceHolder ?? item?.Placeholder;
          if (!!item?.FlgObligatorio) placeholder = `${placeholder} (*)`;

          let update = (Number(batteryData?.Id) !== -1 || !batteryData?.Id) ? true : false;
          let id = "";

          switch (item?.IdDet) {
            case AppConfig.vehicleItemsType.PLACA://"Placa de auto"
              id = "plateInput"
              break;
            case AppConfig.vehicleItemsType.TIPO_USO://"Tipo de uso"
              id = "useInput"
              break;
            default:
              break;
          }

          if (store?.userAppCorp) {
            if (item?.IdCab !== 1 && ((item?.IdDet === AppConfig.vehicleItemsType.COMBUSTION) || (item?.IdDet === AppConfig.vehicleItemsType.TIPO_USO))) {
              return <div key={i}></div>;
            }
          }

          return (
            <MultiInput
              key={i}
              id={id}
              data={item}
              requred={item?.FlgObligatorio}
              placeholder={placeholder}
              className="input-battery"
              parent="DatosVehiculo"
              keyword={JSON.stringify(item)}
              update={update}
              disabled={(Number(batteryData?.Id) === -1 || (store?.userAppCorp && batteryCorpList?.MiniCodigos === null)) ? false : true}
              setWarrantyData={(value: any) => {
                if (
                  item?.PlaceHolder === "Combustión" ||
                  (item?.IdDet === AppConfig.vehicleItemsType.COMBUSTION && item?.IdCab !== 1)
                ) {
                  setComb(value);
                }
                if (
                  item?.PlaceHolder === "Tipo de Uso" ||
                  (item?.IdDet === AppConfig.vehicleItemsType.TIPO_USO && item?.IdCab !== 1)
                ) {
                  setTU(value);
                }
              }}
              onChange={(e: any) => {
                if (!showUpd) setUpd(true);
              }}
              callback={updateRequest}
            />
          );
        })}

        {store?.userAppCorp ?
          <FormControl fullWidth>
            <InputLabel id="select-garantia-label">
              {i18n.WARRANTY_TIME} (*)
            </InputLabel>
            <Select
              key={Math.random()}
              className="input-battery"
              labelId="select-garantia-label"
              id="select-garantia"
              value={TiempoGarantia}
              label="TiempoGarantia"
              name="tiempoGarantia"
              onChange={(event) => {
                // props.setCustomRev(event.target.value);
                setTiempoGarantia(event.target.value);
                handleInputChangeCorp(event);
              }}
              disabled={(store?.userAppCorp && batteryCorpList?.MiniCodigos === null) ? false : true}
              data-keyword={"TiempoGarantia"}
              data-parent={"TiempoGarantia"}
              required={true}
            >
              {/* <option aria-label="None" value="" /> */}
              {store?.selectData?.TiempoGarantia?.map((item: any, i: number) => {
                return (
                  <MenuItem key={`TG${i}`} value={item.Cod}>{item.Name} {i18n.MONTHS}</MenuItem>
                )
              })}
            </Select>
          </FormControl>
          : <></>}

        {store?.userAppCorp ?
          <FormControl fullWidth>
            <InputLabel id="select-mantenimiento-label" >
              {i18n.REVIEW_PERIOD} (*)
            </InputLabel>
            <Select
              key={Math.random()}
              className="input-battery"
              labelId="select-mantenimiento-label"
              id="select-mantenimiento"
              value={Mantenimiento}
              label="PeriodoRevision"
              name="periodoRevision"
              onChange={(event) => {
                // props.setCustomRev(event.target.value);
                setMantenimiento(event.target.value);
                handleInputChangeCorp(event);
              }}
              disabled={(store?.userAppCorp && batteryCorpList?.MiniCodigos === null) ? false : true}
              data-keyword={"PeriodoRevision"}
              data-parent={"PeriodoRevision"}
              required={true}
            >
              {/* <option aria-label="None" value="" /> */}
              {store?.selectData?.PeriodoRevision?.map((item: any, i: number) => {
                return (
                  <MenuItem key={`PR${i}`} value={item.Cod}>{item.Name} {i18n.MONTHS}</MenuItem>
                )
              })}
              {/* <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={6}>6</MenuItem> */}
            </Select>
          </FormControl>
          : <></>}

      </div>
      {/* {!!showUpd && (
        <Button
          fullWidth
          variant="contained"
          className="btn-success"
          color="secondary"
          onClick={updateRequest}
        >
          <strong>Actualizar</strong>
        </Button>
      )} */}
    </details>
  );
};

interface NewBatteryProps {
  callback?: any;
}

// var elementForm: any;

const RenderByCountry = (props: any) => {
  const [store, dispatch] = React.useContext(StoreContext);
  const [customWarranty, setCustomWarranty] = React.useState<any>();
  const [customRev, setCustomRev] = React.useState<any>();
  // const [openInfoBattery, setOpenInfoBattery] = React.useState(props?.openInfoBattery);

  switch (store?.user?.Country) {
    case "PE":
    case "NAC":
      return (
        <>
          <DisplaySelects
            customWarranty={customWarranty}
            setCustomWarranty={setCustomWarranty}
            customRev={customRev}
            setCustomRev={setCustomRev}

          // openInfoBattery={props?.openInfoBattery}
          // setOpenInfoBattery={props?.setOpenInfoBattery}
          />
          {!store?.userAppCorp && (<UserData />)}

          <CarData
            setCustomWarranty={setCustomWarranty}
            setCustomRev={setCustomRev}
          />
        </>
      );
      break;
    case "EC":
      return (
        <>
          <DisplaySelects
            customWarranty={customWarranty}
            setCustomWarranty={setCustomWarranty}
            customRev={customRev}
            setCustomRev={setCustomRev}
          />
          {!store?.userAppCorp && (<Ecuador />)}
          <CarData
            setCustomWarranty={setCustomWarranty}
            setCustomRev={setCustomRev}
          />
        </>
      );
      break;
    case "VE":
      return (
        <>
          <DisplaySelects
            customWarranty={customWarranty}
            setCustomWarranty={setCustomWarranty}
            customRev={customRev}
            setCustomRev={setCustomRev}
          />
          {!store?.userAppCorp && (<Venezuela />)}

          <CarData
            setCustomWarranty={setCustomWarranty}
            setCustomRev={setCustomRev}
          />
        </>
      );
      break;
    case "CL":
      return (
        <>
          <DisplaySelects
            customWarranty={customWarranty}
            setCustomWarranty={setCustomWarranty}
            customRev={customRev}
            setCustomRev={setCustomRev}
          />

          {!store?.userAppCorp && (<Chile />)}

          <CarData
            setCustomWarranty={setCustomWarranty}
            setCustomRev={setCustomRev}
          />
        </>
      );
      break;

    case "DO":
      return (
        <>
          <DisplaySelects
            customWarranty={customWarranty}
            setCustomWarranty={setCustomWarranty}
            customRev={customRev}
            setCustomRev={setCustomRev}
          />

          {!store?.userAppCorp && (<ReplublicaDominicana />)}

          <CarData
            setCustomWarranty={setCustomWarranty}
            setCustomRev={setCustomRev}
          />
        </>
      );
      break;
    case "BO":
      return (
        <>
          <DisplaySelects
            customWarranty={customWarranty}
            setCustomWarranty={setCustomWarranty}
            customRev={customRev}
            setCustomRev={setCustomRev}
          />
          {!store?.userAppCorp && (<Bolivia />)}

          <CarData
            setCustomWarranty={setCustomWarranty}
            setCustomRev={setCustomRev}
          />
        </>
      );
      break;
    case "TT":
      return (
        <>
          <DisplaySelects
            customWarranty={customWarranty}
            setCustomWarranty={setCustomWarranty}
            customRev={customRev}
            setCustomRev={setCustomRev}
          />

          {!store?.userAppCorp && (<TrinidadYTobago />)}

          <CarData
            setCustomWarranty={setCustomWarranty}
            setCustomRev={setCustomRev}
          />
        </>
      );
      break;
    default:
      return <></>;
      break;
  }
};

export const NewBattery = React.forwardRef((props: NewBatteryProps, ref) => {

  const [dense, setDense] = React.useState(false);
  const [index, setIndex] = React.useState(0);
  const [store, dispatch] = React.useContext(StoreContext);
  const [open, setOpen] = React.useState(store?.openMaintenance);
  const [loading, setLoad] = React.useState(false);
  const [newMant, setMant] = React.useState(store?.openMaintenance);
  const { battery, batteryCorpList } = store;

  const AlertRef = React.useRef<any>();
  const [openInfoBattery, setOpenInfoBattery] = React.useState(true);

  const [selectedAddressSucursal, setSelectedAddressSucursal] = React.useState(store?.selectedAddressSucursal ?? false);
  const [objectAddress, setObjectAddress] = React.useState<any>(store?.objAddressSucursal ?? null);

  const [openAddressSucursal, setOpenAddressSucursal] = React.useState(false);
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);

  const [corpBatteryListSave, setCorpBatteryListSave] = React.useState<any>(null);
  const [disabledButtonRegister, setDisabledButtonRegister] = React.useState(false);

  const [corpDataMaintenance, setCorpDataMaintenance] = React.useState<any>(null);

  const [fileCpe, setFileCpe] = React.useState('');
  const [fileCpeName, setFileCpeName] = React.useState('');


  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  const updateAddressSucursal = (address: any, close: boolean) => {
    if (!close) {
      setSelectedAddressSucursal(true);
      setObjectAddress(address);
    }
    setOpenAddressSucursal(false);
  }

  React.useEffect(() => {
    if ((store?.userAppCorp && (batteryCorpList?.MiniCodigos) !== null)) {
      updateAddressSucursal({
        Id: batteryCorpList?.IdDireccion,
        Address: batteryCorpList?.Direccion
      }, false);

    }

    if (store?.user?.GroupCode === "105") {
      setFileCpe((store?.userAppCorp ? batteryCorpList?.UrlComprobante ?? '' : battery?.UrlComprobante ?? ''));
    }

  }, [store?.userAppCorp, batteryCorpList, store?.user, battery?.UrlComprobante]);

  const closeToggle = async (maintenance: { index?: number, findKey?: Boolean, idTarjetaGarantia?: number }) => {

    if (maintenance) {
      const { index = 0, findKey = false, idTarjetaGarantia } = maintenance;

      if (store?.userAppCorp && idTarjetaGarantia) {
        setLoad(true);
        await getDataMaintenance(idTarjetaGarantia, index);
      }

      let newIndex = index;
      if (findKey)
        newIndex = battery?.DatosMantenimiento?.findIndex(
          (item: any) => item.Id === index
        );

      setIndex(newIndex);
    }

    if (newMant) {
      setMant(false);
    }

    setOpen(!open);
    setLoad(false);
  };

  const getDataMaintenance = async (idTarjeta: number, idRevision: number) => {

    const objRequestMaintenance = {
      idTarjetaGarantia: idTarjeta,
      idRevision: idRevision
    }

    await CorpGetWarrantyCardsMaintenanceById(objRequestMaintenance).then((response) => {

      if (response?.statusCode !== 200) {
        return AlertRef?.current?.open(response?.message ?? "Ocurrio un error en el servidor", "error");
      }

      if (response?.statusCode === 200) {
        const dataResponse = {
          Modelo: batteryCorpList?.NomModelo,
          DatosBaterias: batteryCorpList?.DatosBaterias,
          ...response.value,
        }
        setCorpDataMaintenance(dataResponse);
      }
    })

  }

  // const BatteryInfo = () => {
  //   return (
  //     <div className="battery-container">
  //       <Box sx={{ flexGrow: 1 }} className="content-battery-code">
  //         <Grid container spacing={1}>
  //           <Grid item xs={12}>
  //             <div className="battery-detail-container battery-detail-name">
  //               <Typography variant="h6" align="center" className="uppercase">
  //                 {battery?.IdMiniCod}
  //               </Typography>
  //               <Typography
  //                 variant="caption"
  //                 align="center"
  //                 className="uppercase"
  //               >
  //                 {/* Mod: {battery?.Modelo} | Letra: {battery?.Letra} */}
  //               </Typography>
  //             </div>
  //           </Grid>

  //           <Grid item xs={3}>
  //             <div className="battery-detail-container">
  //               <span className="subtitle">Modelo</span>
  //               <span className="title">{battery?.Modelo}</span>

  //             </div>
  //           </Grid>

  //           <Grid item xs={2}>
  //             <div className="battery-detail-container">
  //               <span className="subtitle">Voltaje</span>
  //               <span className="title">{battery?.Voltaje}V</span>

  //             </div>
  //           </Grid>
  //           <Grid item xs={2}>
  //             <div className="battery-detail-container">
  //               <span className="subtitle">CN</span>
  //               <span className="title">{battery?.CapacidadNominal}Ah</span>

  //             </div>
  //           </Grid>
  //           <Grid item xs={2}>
  //             <div className="battery-detail-container">
  //               <span className="subtitle">CCA</span>
  //               <span className="title">
  //                 {battery?.CorrienteArranqueEnFrio}A
  //               </span>

  //             </div>
  //           </Grid>
  //           <Grid item xs={3}>
  //             <div className="battery-detail-container">
  //               <span className="subtitle">Densidad</span>
  //               <span className="title">{battery?.Densidad || 0}</span>

  //             </div>
  //           </Grid>
  //           {/* <Grid item xs={2}>
  //             <div className="battery-detail-container">
  //               <CheckIcon color="success" />
  //             </div>
  //           </Grid> */}
  //         </Grid>
  //       </Box>
  //     </div>
  //   );
  // };

  const MaintenanceData = () => {

    const batteryDataMantenimiento = (store?.userAppCorp) ? batteryCorpList?.DatosRevisiones : battery?.DatosMantenimiento;

    const [data, setData] = React.useState(
      (store?.userAppCorp) ? batteryDataMantenimiento :
        batteryDataMantenimiento?.length > 1
          ? batteryDataMantenimiento?.filter(
            (dataItem: any) => dataItem?.Id !== 0
          )
          : []
    );

    if (batteryDataMantenimiento && ((batteryDataMantenimiento?.length > 1) || (store?.userAppCorp && batteryDataMantenimiento.length > 0))) {
      return (
        // <details className="_details" open></details>
        <div className="container-details-maintenance _details">
          {/* <summary>
            <strong>Datos de mantenimiento</strong>
            <div className="arrow-container">
              <ArrowDropDownIcon />
            </div>
          </summary> */}
          <List dense={dense}>
            {data
              .sort((a: any, b: any) => {
                if ((a?.Id ?? a?.IdMantenimiento) < (b?.Id ?? b?.IdMantenimiento)) return -1;
                if ((a?.Id ?? a?.IdMantenimiento) > (b?.Id ?? b?.IdMantenimiento)) return 1;
                return 0;
              })
              .map((item: any, key: number) => (
                <ListItem
                  key={key}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="mantenimiento"
                      onClick={() => {
                        closeToggle({
                          index: (item.Id ?? item?.IdMantenimiento),
                          findKey: !(store?.userAppCorp),
                          idTarjetaGarantia: (store?.userAppCorp ? batteryCorpList?.IdTarjeta : null)
                        })
                      }
                      }
                      color="inherit"
                    >
                      <VisibilityIcon />
                    </IconButton>
                  }
                >
                  <ListItemAvatar style={{ position: "absolute", minHeight: "20px", left: "130px", top: "22%" }}>
                    <Avatar style={{ width: "20px", height: "20px", color: "black", fontSize: "0.9rem", fontWeight: "bold" }}>{item.Id}</Avatar>
                  </ListItemAvatar>
                  <ListItemText

                    primary={store?.userAppCorp ? i18n.REVISION : i18n.MAINTENANCE}
                    secondary={`${i18n.DATE}: ${moment(item.FecReg ?? item?.FecMantenimiento).format("DD/MM/yyyy")}`}
                  />
                </ListItem>
              ))}
          </List>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const setAddressDebounce = useDebounce((response: any) => {
    setLoad(false)
    AlertRef?.current?.open(response?.message ?? "Registro exitoso", "success");

    if (!!props?.callback) {
      props.callback();
      return;
    }
    // dispatch({ type: types.newBattery, payload: response?.Id });
  }, 400);

  const getCorpBatteryList = () => {
    const objCorp = {
      QRcodes: store?.searchCode,
      origenBat: store?.user?.Country
    }

    new Promise(function (resolve, reject) {
      getCorpWarrantyMinicod(objCorp)
        .then((data) => {
          if (data.statusCode === 200) {

            if (data.size <= 0) { return; }

            const arDataBatteries = data?.value ?? [];

            let objSend = {
              clientAddress: store?.clientAddress,
              dataWarranty: arDataBatteries,
              selectData: store?.selectData,
              searchCodes: store?.searchCode,
            };

            setCorpBatteryListSave(objSend);
            // resolve(dispatch({ type: types.setCorpBatteryList, payload: objSend }));
          }
        })
        .catch((error) => {
          // AlertRef?.current?.open("Codigo no encontrado", "error");
        });
    })

  }

  const setCorpBatteryList = () => {
    dispatch({ type: types.setCorpBatteryList, payload: corpBatteryListSave });
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLoad(true);

    let DatosUsuario: any = [],
      DatosVehiculo: any = [],
      Garantia: any = [],
      Mantenimiento: any = [],
      DatosMantenimiento: any = [];
    let { battery, batteryCorpList } = store;

    battery['UrlComprobante'] = fileCpe;

    if (batteryCorpList !== undefined) {
      batteryCorpList['UrlComprobante'] = fileCpe;
    }

    const batteryCorpListAux = { ...batteryCorpList };
    let clientPhoneCorp = "";

    for (const key in e.target.elements) {
      if (Object.prototype.hasOwnProperty.call(e.target.elements, key)) {
        const element = e.target.elements[key];
        if (
          !isNaN(Number(key)) &&
          (element.tagName === "INPUT" ||
            (element.tagName !== "FIELDSET" && element.tagName !== "BUTTON"))
        ) {
          if (element.tagName === "INPUT") {
            let originalObj = !!Object.keys(
              element.parentElement.parentElement.dataset
            ).length
              ? element.parentElement.parentElement.dataset
              : !!Object.keys(
                element.parentElement.parentElement.parentElement.dataset
              ).length
                ? element.parentElement.parentElement.parentElement.dataset
                : element.parentElement.dataset;

            if (originalObj.parent === "DatosUsuario") {
              let newObj = JSON.parse(originalObj.keyword);
              newObj["Valor"] = element.value;

              if (newObj['IdDet'] === AppConfig.userItemsType.DOCUMENT) {
                if (store?.user?.Country === "BO") {
                  const docComplement = document.querySelector<HTMLInputElement>('#docNacComplement')?.value;
                  const docExpedition = document.querySelector<HTMLInputElement>('#docNacExpedition')?.value;

                  if (window?.document?.getElementById("tipDoc")?.textContent === "CI") {
                    newObj["Valor"] = `${element.value} ${docComplement} ${docExpedition?.toUpperCase()}`;
                  }
                }
              }

              if (store?.userAppCorp && newObj['IdDet'] === AppConfig.userItemsType.PHONE) {
                clientPhoneCorp = newObj["Valor"];
              }

              DatosUsuario.push(newObj);
            }
            if (originalObj.parent === "DatosVehiculo") {
              let newObj = JSON.parse(originalObj.keyword);
              newObj["Valor"] = element.value;

              if (store?.userAppCorp) {
                if (newObj['IdDet'] === AppConfig.vehicleItemsType.PLACA) {
                  batteryCorpListAux['PlacaVehiculo'] = newObj["Valor"];
                }

                if (newObj['IdDet'] === AppConfig.vehicleItemsType.MARCA) {
                  batteryCorpListAux['NomMarca'] = newObj["Valor"];
                  let dataMarModelo = store?.selectData.MarModeloV.find((f: any) => f.Name === newObj["Valor"]);
                  batteryCorpListAux['CodMarca'] = dataMarModelo?.Cod;

                  /* if ((newObj['FlgObligatorio'] === true || newObj["Valor"] !== "")) {
                    if( !dataMarModelo ){
                      setLoad(false);
                      return AlertRef?.current?.open(
                        "Debe seleccionar una Marca de la lista",
                        "error"
                      );
                    }
                  } */

                }

                if (newObj['IdDet'] === AppConfig.vehicleItemsType.MODELO) {
                  batteryCorpListAux['NomModelo'] = newObj["Valor"];
                  let dataModelo = store?.selectData.ModeloV.find((f: any) => f.Name === newObj["Valor"]);
                  batteryCorpListAux['CodModelo'] = dataModelo?.Cod;

                  /* if ((newObj['FlgObligatorio'] === true || newObj["Valor"] !== "")) {
                    if( !dataModelo ){
                      setLoad(false);
                      return AlertRef?.current?.open(
                        "Debe seleccionar un Modelo de la lista",
                        "error"
                      );
                    }
                  } */

                }

                if (newObj['IdDet'] === AppConfig.vehicleItemsType.UNIDAD_MOBILE) {
                  batteryCorpListAux['NroUnidad'] = newObj["Valor"];
                }
              }

              DatosVehiculo.push(newObj);
            }

            if (originalObj.parent === "DatosMantenimiento") {
              let newObj = JSON.parse(originalObj.keyword);
              newObj["Valor"] = element.value;
              DatosMantenimiento.push(newObj);
            }
            if (originalObj.parent === "Garantia") {
              battery["Garantia"] = element.value;
            }

            if (originalObj.parent === "Mantenimiento") {
              battery["Mantenimiento"] = element.value;
            }

            if (originalObj.parent === "PeriodoRevision" && store?.userAppCorp) {
              batteryCorpListAux["FrecManto"] = element.value;
            }

            if (originalObj.parent === "TiempoGarantia" && store?.userAppCorp) {
              batteryCorpListAux["TiempoGar"] = element.value;
            }

            if (Number(key) === 0 && !store?.userAppCorp) {
              battery["FechaVenta"] = moment(
                element.value,
                "DD/MM/YYYY"
              ).format();
              battery["FecReg"] = moment(element.value, "DD/MM/YYYY").format();
              battery["FecUpd"] = moment(element.value, "DD/MM/YYYY").format();
            }
          }
        }
      }
    }

    if (!store?.userAppCorp) {
      if ((battery['Garantia'] === "" || battery['Mantenimiento'] === "")) {
        document.getElementById("bmgInfoBattery")?.setAttribute('open', 'true');
        document.getElementById('bmgDataVehicle')?.setAttribute('open', 'true');
        // setOpenInfoBattery(!openInfoBattery);

        const dropdownGarantia = document.getElementById('select-garantia');
        const dropdownPeriodo = document.getElementById('select-mantenimiento');

        dropdownGarantia?.focus();
        if (battery['Mantenimiento'] === "" && battery['Garantia'] !== "") {
          dropdownPeriodo?.focus();
        }

        AlertRef?.current?.open('Por favor, completa los campos "plazo de garantía" y "periodo de revisión" para continuar con el registro', "error");
        setLoad(false);
        return;
      }
    }



    if (!store?.userAppCorp) {
      battery["DatosUsuario"] = DatosUsuario;
      battery["DatosVehiculo"] = DatosVehiculo;
      // Tarjeta con codigo
      battery["Tipo"] = "TC";
    }

    if (store?.userAppCorp) {
      batteryCorpListAux["DatosVehiculo"] = DatosVehiculo;

      const currentDate = new Date();
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');

      batteryCorpListAux["Periodo"] = month;

      // Tarjeta con codigo
      // batteryCorpList["Tipo"] = "TC";
    }

    /* // tarjeta sin codigo
    battery["TIPO"] = "TS"
    // solicitud con codigo
    battery["TIPO"] = "SC"
    // solicitud sin codigo
    battery["TIPO"] = "SS" */

    if (((!store?.userAppCorp && Number(battery?.Id) !== -1) || (store?.userAppCorp && (batteryCorpList?.MiniCodigos) !== null))) {
      let data =
        ACTIVE_UPDATE === "USER"
          ? battery["DatosUsuario"]
          : battery["DatosVehiculo"];
      let obj = {
        id: Number(battery?.Id),
        data,
      };

      WarrantyCardDetail(obj).then(() => {
        ACTIVE_UPDATE = "";

        dispatch({ type: types.saveBattery, payload: battery });
        alert("Registro actualizado");
        setLoad(false);
      });
    } else {
      if (!!Object.keys(store.selected_client).length) {
        let value: any = window?.document?.getElementById("docNac")
        let valuePhone: any = window?.document?.getElementById("numPhone")
        let inputSelectCountry: any = {
          ve_tipDoc: window?.document?.getElementById("tipDoc")?.textContent,
          ve_docNac: value?.value,
          ve_codePhone: window?.document?.getElementById("codePhone")?.textContent,
          ve_numPhone: valuePhone?.value,
          ch_tipDoc: window?.document?.getElementById("tipDoc")?.textContent,
          ec_tipDoc: window?.document?.getElementById("tipDoc")?.textContent,
          do_tipDoc: window?.document?.getElementById("tipDoc")?.textContent,
          bo_tipDoc: window?.document?.getElementById("tipDoc")?.textContent,
          // valores globales para cada pais
          numDocNac: value?.value,
          codePhone: window?.document?.getElementById("codePhone")?.textContent,
          numPhone: valuePhone?.value,
        };

        if (!store?.userAppCorp) {
          dispatch({
            type: types.openAddress,
            payload: battery,
            inputSelectCountry,
          });
        }

        if (store?.userAppCorp) {
          new Promise((resolve, reject) => {
            if (!!objectAddress) {

              const qrCodeList = store?.searchCode;

              batteryCorpListAux["Celular"] = clientPhoneCorp;
              batteryCorpListAux["MiniCodigos"] = qrCodeList;
              batteryCorpListAux["CantMiniCodigos"] = qrCodeList ? qrCodeList?.split(',')?.length : 0;
              batteryCorpListAux["OrigenBat"] = store?.user?.Country;
              batteryCorpListAux["CardCode"] = store?.selected_client?.Id;
              // battery["CelularEmpresa"] = store?.selected_client?.Phone;
              // battery["EmailEmpresa"] = store?.selected_client?.Email;
              batteryCorpListAux["CardName"] = store?.selected_client?.RzSocial;
              batteryCorpListAux["CodUserReg"] = store?.user?.user_login;
              batteryCorpListAux["TipoUserReg"] = store?.user?.tipo;
              batteryCorpListAux["NomUserReg"] = store?.user?.name;

              batteryCorpListAux["IdDireccion"] = objectAddress.Id;
              batteryCorpListAux["Direccion"] = objectAddress.Address;
              batteryCorpListAux["Latitud"] = objectAddress.Latitud;
              batteryCorpListAux["Longitud"] = objectAddress.Longitud;

              CorpWarrantyCard(batteryCorpListAux)
                .then((response) => {

                  if (response.statusCode === 200) {

                    clientPointsAddExt({ cardCode: store?.user?.user_login/* store?.selected_client?.UserId */, puntos: batteryCorpListAux["CantMiniCodigos"] });

                    // setAddressDebounce(response);
                    // getCorpBatteryList();

                    /*if ((store?.user?.Country === "NAC" || store?.user?.Country === "PE")) {
                      try {
                        const clientPhone = `51${clientPhoneCorp}`;
                        const clientName = store?.selected_client?.RzSocial;

                        if (clientPhone?.trim().length > 2) {
                          const dataRequestMessage = {
                            phoneClient: clientPhone?.replace(' ', '')?.trim(),
                            appCorp: true,
                            paramsMsg: [
                              clientName, response.value
                            ]
                          }

                          bmgSendMessage(dataRequestMessage).then(() => { });
                        } 
                      } catch (error) {
                        console.error(error);
                      }
                    }*/

                    batteryCorpListAux["IdTarjeta"] = response.value;

                    let objSend = {
                      clientAddress: store?.clientAddress,
                      dataWarranty: batteryCorpListAux,
                      selectData: store?.selectData,
                      searchCodes: store?.searchCode,
                    };

                    setCorpBatteryListSave(objSend);

                    setOpenSuccessModal(true);
                    setDisabledButtonRegister(true);
                  }

                  if (response.statusCode !== 200) {
                    setLoad(false);
                    reject(response?.message);
                    AlertRef?.current?.open("Error al insertar registro", "error");
                  }
                })
                .catch((error) => {
                  setLoad(false);
                  AlertRef?.current?.open("Error en el servidor", "error");
                  return;
                });

            } else {
              reject("Debe seleccionar una dirección de cliente");
              AlertRef?.current?.open(
                "Debe seleccionar una dirección de cliente",
                "error"
              );
            }
          }).catch(err => {
            AlertRef?.current?.open(err, "error");
          })
        }

      } else {
        alert("Debe seleccionar un cliente");
        setLoad(false);
      }
    }
  };

  const validateForm = () => {
    return new Promise(function (resolve, reject) {
      const frm: any = document.getElementById("frm-battery");
      let DatosUsuario: any = [],
        DatosVehiculo: any = [],
        Garantia: any = [],
        Mantenimiento: any = [],
        DatosMantenimiento: any = [];
      let { battery, batteryCorpList } = store;

      // const batteryCorpListAux = { ...batteryCorpList };

      for (const key in frm.elements) {
        if (Object.prototype.hasOwnProperty.call(frm.elements, key)) {
          const element = frm.elements[key];
          if (
            !isNaN(Number(key)) &&
            (element.tagName === "INPUT" ||
              (element.tagName !== "FIELDSET" &&
                element.tagName !== "BUTTON"))
          ) {
            if (element.tagName === "INPUT") {
              let originalObj = !!Object.keys(
                element.parentElement.parentElement.dataset
              ).length
                ? element.parentElement.parentElement.dataset
                : !!Object.keys(
                  element.parentElement.parentElement.parentElement.dataset
                ).length
                  ? element.parentElement.parentElement.parentElement.dataset
                  : element.parentElement.dataset;
              if (originalObj.parent === "DatosUsuario" && element.value !== "") {
                let newObj = JSON.parse(originalObj.keyword);
                newObj["Valor"] = element.value;
                DatosUsuario.push(newObj);
              }
              if (originalObj.parent === "DatosVehiculo") {
                let newObj = JSON.parse(originalObj.keyword);
                newObj["Valor"] = element.value;
                DatosVehiculo.push(newObj);

                /* if (store?.userAppCorp) {
                  if (newObj['DescDet'] === "Marca de auto" && (newObj['FlgObligatorio'] === true || newObj["Valor"] !== "")) {
                    let dataMarModelo = store?.selectData.MarModeloV.find((f: any) => f.Name === newObj["Valor"]);

                    if( !dataMarModelo ){
                      return AlertRef?.current?.open(
                        "Debe seleccionar una Marca de la lista",
                        "error"
                      );
                    }
                  }
  
                  if (newObj['DescDet'] === "Modelo de auto" && (newObj['FlgObligatorio'] === true || newObj["Valor"] !== "")) {
                    let dataModelo = store?.selectData.ModeloV.find((f: any) => f.Name === newObj["Valor"]);

                    if( !dataModelo ){
                      return AlertRef?.current?.open(
                        "Debe seleccionar un Modelo de la lista",
                        "error"
                      );
                    }
                  }
                } */

              }
              if (originalObj.parent === "DatosMantenimiento") {
                let newObj = JSON.parse(originalObj.keyword);
                newObj["Valor"] = element.value;
                DatosMantenimiento.push(newObj);
              }
              if (originalObj.parent === "Garantia" && element.value !== "") {
                battery["Garantia"] = element.value;
              }
              if (originalObj.parent === "Mantenimiento" && element.value !== "") {
                battery["Mantenimiento"] = element.value;
              }

              if (originalObj.parent === "PeriodoRevision" && store?.userAppCorp) {

                battery["FrecManto"] = element.value;
              }

              if (originalObj.parent === "TiempoGarantia" && store?.userAppCorp) {
                battery["TiempoGar"] = element.value;
              }

              if ((Number(key) === 0) && element.value !== "") {
                battery["FechaVenta"] = moment(
                  element.value,
                  "DD/MM/YYYY"
                ).format();
                battery["FecReg"] = moment(
                  element.value,
                  "DD/MM/YYYY"
                ).format();
                battery["FecUpd"] = moment(
                  element.value,
                  "DD/MM/YYYY"
                ).format();
              }
            }
          }
        }
      }

      if (!store?.userAppCorp) {
        battery["DatosUsuario"] = DatosUsuario;
        battery["DatosVehiculo"] = DatosVehiculo;
        // Tarjeta con codigo
        battery["Tipo"] = "TC";
      }

      if (store?.userAppCorp) {
        battery["DatosVehiculo"] = DatosVehiculo;
        // batteryCorpList["DatosVehiculo"] = DatosVehiculo;
        // Tarjeta con codigo
        // batteryCorpList["Tipo"] = "TC";
      }

      // const responseBattery = (store?.userAppCorp) ? batteryCorpList : battery;
      const responseBattery = battery;

      resolve(responseBattery);
    });
  }

  React.useImperativeHandle(ref, () => ({
    saveBattery: () => {
      return new Promise(function (resolve, reject) {
        const frm: any = document.getElementById("frm-battery");
        let DatosUsuario: any = [],
          DatosVehiculo: any = [],
          Garantia: any = [],
          Mantenimiento: any = [],
          DatosMantenimiento: any = [];
        let { battery, batteryCorpList } = store;
        for (const key in frm.elements) {
          if (Object.prototype.hasOwnProperty.call(frm.elements, key)) {
            const element = frm.elements[key];
            if (
              !isNaN(Number(key)) &&
              (element.tagName === "INPUT" ||
                (element.tagName !== "FIELDSET" &&
                  element.tagName !== "BUTTON"))
            ) {
              if (element.tagName === "INPUT") {
                let originalObj = !!Object.keys(
                  element.parentElement.parentElement.dataset
                ).length
                  ? element.parentElement.parentElement.dataset
                  : !!Object.keys(
                    element.parentElement.parentElement.parentElement.dataset
                  ).length
                    ? element.parentElement.parentElement.parentElement.dataset
                    : element.parentElement.dataset;
                if (originalObj.parent === "DatosUsuario" && element.value !== "") {
                  let newObj = JSON.parse(originalObj.keyword);
                  newObj["Valor"] = element.value;
                  DatosUsuario.push(newObj);
                }
                if (originalObj.parent === "DatosVehiculo") {
                  let newObj = JSON.parse(originalObj.keyword);
                  newObj["Valor"] = element.value;
                  DatosVehiculo.push(newObj);
                }
                if (originalObj.parent === "DatosMantenimiento") {
                  let newObj = JSON.parse(originalObj.keyword);
                  newObj["Valor"] = element.value;
                  DatosMantenimiento.push(newObj);
                }
                if (originalObj.parent === "Garantia" && element.value !== "") {
                  battery["Garantia"] = element.value;
                }
                if (originalObj.parent === "Mantenimiento" && element.value !== "") {
                  battery["Mantenimiento"] = element.value;
                }
                if ((Number(key) === 0) && element.value !== "") {
                  battery["FechaVenta"] = moment(
                    element.value,
                    "DD/MM/YYYY"
                  ).format();
                  battery["FecReg"] = moment(
                    element.value,
                    "DD/MM/YYYY"
                  ).format();
                  battery["FecUpd"] = moment(
                    element.value,
                    "DD/MM/YYYY"
                  ).format();
                }
              }
            }
          }
        }

        if (!store?.userAppCorp) {
          battery["DatosUsuario"] = DatosUsuario;
          battery["DatosVehiculo"] = DatosVehiculo;
          // Tarjeta con codigo
          battery["Tipo"] = "TC";
        }

        if (store?.userAppCorp) {
          batteryCorpList["DatosVehiculo"] = DatosVehiculo;
          // Tarjeta con codigo
          batteryCorpList["Tipo"] = "TC";
        }

        const responseBattery = (store?.userAppCorp) ? batteryCorpList : battery;

        resolve(responseBattery);
      });
    },
  }));

  const onChangeFile = (e: any) => {
    const file = e.target.files[0];

    if (!file) return;

    const fileName = file.name;

    const fileExtension = file.name.split('.').pop().toLowerCase();
    const reader = new FileReader();

    setFileCpeName('Subiendo archivo...');

    reader.onloadend = function () {

      const base64String: string = (reader.result as string);
      const byteString: string = base64String ? base64String.split(',')[1] : '';

      const dataRequest = {
        bytes: byteString,
        extension: fileExtension
      }

      fileUpload(dataRequest).then((response) => {
        if (response.status === 'ok') {
          setFileCpe(response.data);
          setFileCpeName(fileName);
        }
      })
    };

    reader.readAsDataURL(file);
  };

  const handleOpen = () => {
    setOpenInfoBattery(openInfoBattery);
  }

  const enableRegisterBattery: boolean = (!store?.userAppCorp && Number(battery?.Id) === -1) || (store?.userAppCorp && (batteryCorpList?.MiniCodigos) === null);

  return (
    <div className="new-battery-container">
      {/* <BatteryInfo /> */}
      <form
        action=""
        onSubmit={handleSubmit}
        className="new-battery-container"
        id="frm-battery"
      >
        <div className="details-container">
          <RenderByCountry openInfoBattery={openInfoBattery} setOpenInfoBattery={handleOpen} />
          {((!store?.userAppCorp && Number(battery?.Id) !== -1) || (store?.userAppCorp && batteryCorpList?.MiniCodigos !== null)) && <MaintenanceData />}
        </div>
        <div className="button-container">
          {store?.userAppCorp ? (
            <Button
              fullWidth
              variant={selectedAddressSucursal ? "outlined" : "contained"}
              color={selectedAddressSucursal ? "default" : "primary"}
              className={selectedAddressSucursal ? "btn-address-sucursal-active" : ""}
              style={{ marginBottom: "10px", fontWeight: "bold" }}
              disabled={(store?.userAppCorp && batteryCorpList?.MiniCodigos === null) ? false : true}
              onClick={() => {
                if (!Object.keys(store.selected_client).length) {
                  alert("Debe seleccionar un cliente");
                  setLoad(false);
                  return;
                }

                validateForm().then((data: any) => {

                  const datosVehiculo = data?.DatosVehiculo;
                  let openBattery = false;

                  datosVehiculo.forEach((item: any) => {
                    if (item.FlgObligatorio === true && item.Valor.trim() === "") {
                      openBattery = true;
                    }
                  })

                  if ((data['FrecManto'] === "" || data['FrecManto'] === null) || (data['TiempoGar'] === "" || data['TiempoGar'] === null)) {
                    openBattery = true;
                  }

                  if (openBattery) {
                    document.getElementById('bmgDataVehicle')?.setAttribute('open', 'true');
                    AlertRef?.current?.open('Por favor, completa los campos para continuar con el registro', "error");

                    setLoad(false);
                    return;
                  }

                  if (openBattery === false) {
                    setOpenAddressSucursal(true);
                  }
                })
              }}
            >
              {selectedAddressSucursal ? objectAddress?.Address : `+ ${i18n.CHOOSE_BRANCH}`}
            </Button>
          ) : <></>
          }

          {store?.user?.GroupCode === "105" &&
            <Box marginBottom={"1rem"} style={{ border: "1px solid #000000", padding: "0.2rem", borderRadius: "5px" }}>

              <Box display={"grid"} alignItems={"center"} gridTemplateColumns={"1fr 24px"} columnGap={"1rem"}>

                <label htmlFor="file-cpe-sales" style={{ cursor: enableRegisterBattery ? 'pointer' : 'not-allowed' }}>
                  <Box display={"grid"} alignItems={"center"} gridTemplateColumns={"24px 1fr"} columnGap={"1rem"}>

                    {enableRegisterBattery ? <AddAPhotoIcon sx={{ color: "#000000" }} /> : <div></div>}
                    
                    {enableRegisterBattery && <input
                      type="file"
                      accept="image/*"
                      name="file-cpe-sales"
                      id="file-cpe-sales"
                      className="hidden"
                      onChange={onChangeFile}
                    /> }
                  
                    <Typography style={{ fontWeight: 'bold', fontSize: "0.9rem", textAlign: "center" }}>{(fileCpeName !== '' && enableRegisterBattery) ? fileCpeName : (!enableRegisterBattery && fileCpe ? 'Comprobante de venta' : 'Añade tu comprobante de venta')}</Typography>
                  </Box>
                </label>

                {fileCpe ? <Link href={fileCpe} target="_blank" rel="noopener" color="inherit">
                  <IconButton style={{ padding: '0' }}><OpenInNewIcon sx={{ color: "#000000" }} /></IconButton>
                </Link> : <div></div>}
              </Box>

            </Box>}

          {((!store?.userAppCorp && Number(battery?.Id) !== -1) || (store?.userAppCorp && (batteryCorpList?.MiniCodigos) !== null)) ? (
            <div>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className="hidden"
                type="submit"
                disabled={loading || (store?.user?.GroupCode === '105' && fileCpe === '')}
                id="btn-submit"
              >
                <Loading loading={loading}>
                  <strong>{i18n.REGISTER_BATTERY}</strong>
                </Loading>
              </Button>

              <Button
                fullWidth
                variant="contained"
                color="primary"
                style={{ borderRadius: "20px" }}
                onClick={(e) => {
                  e.preventDefault();

                  setMant(true);

                  if (store?.userAppCorp) {
                    closeToggle({
                      index: -1,
                      findKey: !(store?.userAppCorp),
                      idTarjetaGarantia: batteryCorpList?.IdTarjeta
                    })
                    return;
                  }

                  setIndex(0);
                  setOpen(!open);
                }}
                disabled={loading}
              >
                <Loading loading={loading}>
                  <strong>{store?.userAppCorp ? i18n.ADD_REVISION : i18n.ADD_MAINTENANCE}</strong>
                </Loading>
              </Button>


            </div>
          ) : (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={store?.fetch || store.openAddress || loading || (store?.userAppCorp && selectedAddressSucursal === false) || disabledButtonRegister || (store?.user?.GroupCode === '105' && fileCpe === '')}
              id="btn-submit"
            >
              <Loading loading={store?.fetch || store.openAddress || loading}>
                <strong>{i18n.REGISTER_BATTERY}</strong>
              </Loading>
            </Button>
          )}
        </div>
      </form>

      <AddressModal callback={props.callback} openModalSucursal={openAddressSucursal} onUpdateAddressSucursal={updateAddressSucursal} />
      <LoadingOverlay loading={store?.fetch || store.openAddress || loading} />
      <Alert ref={AlertRef} />

      {openSuccessModal && (
        <SuccessModal
          open={openSuccessModal}
          onClose={() => {
            // setOpenSuccessModal(false);
            // setLoad(false);
            setCorpBatteryList();
          }}
        />
      )}

      {open && (<Maintenance
        open={open}
        handleClose={closeToggle}
        data={store?.userAppCorp ? corpDataMaintenance : battery}
        index={index}
        new={newMant}
      />)}

      <Bottom />
    </div>
  );
});
