import React from 'react';
import AppConfig from '../constants/AppConfig';
import moment from "moment";

const types = {
  authVerification: "AUTH_VERIFICATION",
  authLogin: "AUTH_LOGIN",
  authSave: "AUTH_SAVE",
  authLogout: "AUTH_LOGOUT",
  authLogoutRV: "AUTH_LOGOUT_RV",
  authLogoutSN: "AUTH_LOGOUT_SN",
  logoutClient: "LOGOUT_CLIENT",
  update: "UPDATE_USER",
  setLocation: "SET_LOCATION",
};

const { types: { seller, partner } } = AppConfig

const AuthReducer = (state, action) => {
  // console.log(action)
  switch (action.type) {
    case types.authLogout:
      return {
        ...state,
        user: {
          auth: false,
        },
        clients: [],
        clients_lite: [],
        selected_client: {},
        warranty: {},
        battery: {},
        maintenance: {},
        clientAddress: [],
        pagination: {},
        warranty_activation_list: [],
        warranty_activation_plate_list: [],
        warranty_activation_document_list: [],
        warranty_activation_minicode_list: [],
        searchWarranty: "",
        active_pane: 0,
        fetch: false,
        isLogoutTime: true,
        updateVersionApp: false,
        userAppCorp: false,
        objAddressSucursal: {},
        selectedAddressSucursal: false,
        searchPlateCode: "",
        searchDocumentCode: "",
        searchMiniCode: "",
        downloadReportActivationsExcel: false,
        warranty_list: [],
        openModalBanner: false
      };
    case types.authLogoutRV:
      return {
        ...state,
        user: {
          auth: false,
          tipo: seller,
        },
        clients: [],
        clients_lite: [],
        selected_client: {},
        warranty: {},
        battery: {},
        maintenance: {},
        clientAddress: [],
        pagination: {},
        warranty_activation_list: [],
        warranty_activation_plate_list: [],
        warranty_activation_document_list: [],
        searchWarranty: "",
        active_pane: 0,
        fetch: false,
        isLogoutTime: true,
        updateVersionApp: false,
        userAppCorp: false,
        objAddressSucursal: {},
        selectedAddressSucursal: false,
        searchPlateCode: "",
        searchDocumentCode: "",
        downloadReportActivationsExcel: false,
        warranty_list: [],
        openModalBanner: false
      };
    case types.authLogoutSN:
      return {
        ...state,
        user: {
          auth: false,
          tipo: partner,
        },
        clients: [],
        clients_lite: [],
        selected_client: {},
        warranty: {},
        battery: {},
        maintenance: {},
        clientAddress: [],
        pagination: {},
        warranty_activation_list: [],
        warranty_activation_plate_list: [],
        warranty_activation_document_list: [],
        searchWarranty: "",
        active_pane: 0,
        fetch: false,
        isLogoutTime: true,
        updateVersionApp: false,
        userAppCorp: false,
        objAddressSucursal: {},
        selectedAddressSucursal: false,
        searchPlateCode: "",
        searchDocumentCode: "",
        downloadReportActivationsExcel: false,
        warranty_list: [],
        openModalBanner: false
      };
    case types.logoutClient:
      return {
        ...state,
        selected_client: {},
        warranty: {},
        battery: {},
        maintenance: {},
        clientAddress: [],
        pagination: {},
        warranty_activation_list: [],
        warranty_activation_plate_list: [],
        warranty_activation_document_list: [],
        searchWarranty: "",
        active_pane: 0,
        fetch: false,
        isLogoutTime: true,
        updateVersionApp: false,
        userAppCorp: false,
        objAddressSucursal: {},
        selectedAddressSucursal: false,
        searchPlateCode: "",
        searchDocumentCode: "",
        downloadReportActivationsExcel: false,
        warranty_list: [],
        openModalBanner: false
      };

    case types.authSave:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    case types.authLogin:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
          auth: true,
          dateTimeLogin: (new Date()),
        },
        isLogoutTime: false,
        userAppCorp: false,
        openModalBanner: (action.payload?.banners ?? false),
        // userAppCorp: (action.payload?.GroupCode === userTypeCode.corporate),
        objAddressSucursal: {},
        selectedAddressSucursal: false,
        updateVersionApp: true,
        initDate: new Date(moment().startOf('month').format("LLLL")),
        endDate: new Date(moment().format("LLLL")),
        initDateWarranty: new Date(moment().startOf('month').format("LLLL")),
        endDateWarranty: new Date(moment().format("LLLL")),
        searchPlateCode: "",
        searchDocumentCode: "",
        downloadReportActivationsExcel: false,
        warranty_list: []
      };
    case types.authVerification:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    case types.update:
      return {
        ...state,
        user: {
          auth: true,
          token: 'JasdlkoasDua!"212sa',
          ...action.payload,
        },
      };
    case types.setLocation:
      return {
        ...state,
        user: {
          ...state.user,
          location: action.payload,
        },
      };

    default:
      return state;
  }
};

export { AuthReducer, types }