import React from "react";
import AppConfig from "../constants/AppConfig";


const { validTypes } = AppConfig

const types = {
  setBattery: "SET_BATTERY",
  saveBattery: "SAVE_BATTERY",
  setWarranty: "SET_WARRANTY",
  setMaintenance: "SET_MAINTENANCE",
  setCombustionV: "SET_CombustionV",
  setWarrantyList: "SET_WARRANTY_LIST",
  setWarrantyDetail: "SET_WARRANTY_DETAIL",
  setWarrantyDetailList: "SET_WARRANTY_DETAIL_LIST",
  setMarModeloV: "SET_MarModeloV",
  setViewWarranty: "SET_VIEW_WARRANTY",
  setViewClient: "SET_VIEW_CLIENT",
  setViewBattery: "SET_VIEW_BATTERY",
  setViewDefault: "SET_VIEW_DEFAULT",
  setTipoUsoV: "SET_TipoUsoV",
  addMaintenance: "ADD_MAINTENANCE",
  openAddress: "OPEN_ADDRESS",
  openAddressMaintenance: "OPEN_ADDRESS_MAINTENANCE",
  openAddressNewWarranty: "OPEN_ADDRESS_NEW_WARRANTY",
  openCreateWarrantyRequest: "OPEN_CREATE_WARRANTY_REQUEST",
  // validTypeNewWarranty: "VALID_TYPE_NEW_WARRANTY",
  openSuccessResponse: "OPEN_SUCCESS_RESPONSE",
  closeAddress: "CLOSE_ADDRESS",
  closeAddressMaintenance: "CLOSE_ADDRESS_MAINTENANCE",
  addComment: "ADD_COMMENT",
  setWarrantyData: "SET_WARRANTY_DATA",
  setDataFormWarranty: "SET_DATA_FORM_WARRANTY",
  setBatteryFront: "SET_BATTERY_FRONT",
  newBattery: "NEW_BATTERY",
  setSearchWarranty: "SET_SEARCH_WARRANTY",
  clearSearchWarranty: "CLEAR_SEARCH_WARRANTY",
  setWarrantyActivationList: "SET_WARRANTY_ACTIVATION_LIST",
  setInitDate: "SET_INIT_DATE",
  setEndDate: "SET_END_DATE",
  setWarrantyActivationLisDateStart: "SET_WARRANTY_ACTIVATION_LIST_DATE_START",
  setWarrantyActivationLisDateEnd: "SET_WARRANTY_ACTIVATION_LIST_DATE_END",
  setWarrantyDateStart: "SET_WARRANTY_DATE_START",
  setWarrantyDateEnd: "SET_WARRANTY_DATE_END",
  startFetch: "START_FETCH",
  setWarrantyActivationPlateList: "SET_WARRANTY_ACTIVATION_PLATE_LIST",
  setWarrantyActivationDocumentList: "SET_WARRANTY_ACTIVATION_DOCUMENT_LIST",
  setWarrantyActivationMiniCodeList: "SET_WARRANTY_ACTIVATION_MINICODE_LIST",
  setUpdateVersionApp: "SET_UPDATE_VERSION_APP",

  // corporativo
  setCorpBatteryList: "SET_CORP_BATTERY_LIST",
  setSelectOptionsData: "SET_SELECT_OPTIONS_DATA"
};

const WarrantyReducer = (state, action) => {

  switch (action.type) {
    case types.startFetch:
      return {
        ...state,
        fetch: true,
      };
    case types.setWarranty:
      return {
        ...state,
        warranty: action.payload,
      };
    case types.setBattery:
      return {
        ...state,
        battery: action.payload.dataWarranty,
        selectData: action.payload.selectData,
        clientAddress: action.payload.clientAddress,
        searchCode: action.payload.searchCode,
        inputSelectCountry: {}
      };
    case types.saveBattery:
      return {
        ...state,
        battery: action.payload,
      };
    case types.addMaintenance:
      return {
        ...state,
        battery: {
          ...state.battery,
          DatosMantenimiento: [
            ...state.battery.DatosMantenimiento,
            action.payload,
          ],
        },
      };
    case types.setMaintenance:
      return {
        ...state,
        maintenance: action.payload,
      };

    case types.setCombustionV:
      return {
        ...state,
        CombustionV: action.payload,
      };
    case types.setMarModeloV:
      return {
        ...state,
        MarModeloV: action.payload,
      };
    case types.setTipoUsoV:
      return {
        ...state,
        TipoUsoV: action.payload,
      };
    case types.setWarrantyList:
      return {
        ...state,
        warranty_list: action.payload,
      };
    case types.setWarrantyDetail:
      return {
        ...state,
        openView: validTypes.warrantyDetail,
        openAddress: false,
        warrantyDetail: action.payload,
        newWarranty: {},
      };
    case types.setWarrantyDetailList:
      return {
        ...state,
        openView: validTypes.warrantyDetail,
        fetch: false,
        openAddress: false,
        warrantyDetail: action.payload,
        warranty_list: action.warranty_list ?? [],
        newWarranty: {},
        openSuccessResponse: true,
        openCreateWarrantyRequest: false
      };
    case types.addComment:
      return {
        ...state,
        warrantyDetail: {
          ...state.warrantyDetail,
          Conversacion: [...state.warrantyDetail.Conversacion, action.payload],
        },
      };
    case types.openAddress:
      return {
        ...state,
        inputSelectCountry: action.inputSelectCountry,
        battery: action.payload,
        openAddress: true,
      };
    case types.openAddressMaintenance:
      return {
        ...state,
        battery: action.payload,
        openMaintenance: true,
        openAddress: true,
      };
    case types.openAddressNewWarranty:
      return {
        ...state,
        newWarranty: action.payload,
        openAddress: true,
      };
    case types.openCreateWarrantyRequest:
      return {
        ...state,
        openCreateWarrantyRequest: action.payload,
        // validTypeNewWarranty: action.validType
      };
    case types.openSuccessResponse:
      return {
        ...state,
        openSuccessResponse: action.payload,
      };

    case types.setWarrantyData:
      return {
        ...state,
        battery: action.payload.battery,
        warrantyFront: action.payload.warrantyFront,
        newWarranty: {},
        inputSelectCountry: {}
      };
    case types.setDataFormWarranty:
      return {
        ...state,
        warrantyFront: action.payload,
        newWarranty: {},
        inputSelectCountry: {}
      };
    case types.setViewWarranty:
      return {
        ...state,
        warrantyFront: action.payload,
        newWarranty: {},
        openView: validTypes.warranty,
        openMaintenance: false,
      };
    case types.setViewClient:
      return {
        ...state,
        openView: validTypes.client,
        battery: action.payload,
        openMaintenance: false,
      };
    case types.setViewBattery:
      return {
        ...state,
        openView: validTypes.battery,
        openMaintenance: false,

      };
    case types.setViewDefault:
      return {
        ...state,
        openView: "",
      };
    case types.setBatteryFront:
      return {
        ...state,
        batteryFront: action.payload,
        newBattery: {},
        inputSelectCountry: {}
      };
    case types.closeAddress:
      return {
        ...state,
        openAddress: false,
        // selectedAddressSucursal: action.payload?.selected ?? false,
        // objAddressSucursal: action.payload?.objectAddress ?? {},
      };
    case types.closeAddressMaintenance:
      return {
        ...state,
        fetch: false,
        openAddress: false,
        openMaintenance: false,
        battery: action.payload,
      };
    case types.newBattery:
      return {
        ...state,
        openAddress: false,
        fetch: false,
        inputSelectCountry: {
          ...state.inputSelectCountry,
          ve_docNac: "",
          numDocNac: "",
          numPhone: "",
          codePhone: ""
        },
        battery: {
          ...state.battery,
          Id: action.payload,
        },
      };
    case types.setSearchWarranty:
      return {
        ...state,
        searchWarranty: action.payload,
      };
    case types.clearSearchWarranty:
      return {
        ...state,
        searchWarranty: "",
      };
    case types.setWarrantyActivationList:
      return {
        ...state,
        warranty_activation_list: action.payload,
        downloadReportActivationsExcel: action?.download ?? false,
        // searchMiniCode: action.searchMiniCode,
        pagination: {
          ...state.pagination,
          activationList: action?.pagination
        }
      };
    case types.setWarrantyActivationLisDateStart:
      return {
        ...state,
        initDate: action.payload.selectedDate,
        // warranty_activation_list: action.payload.payload,
      };
    case types.setWarrantyActivationLisDateEnd:
      return {
        ...state,
        endDate: action.payload.selectedDate,
        // warranty_activation_list: action.payload.payload,
      };

    // 
    case types.setWarrantyDateStart:
      return {
        ...state,
        initDateWarranty: action.date,
        // warranty_activation_list: action.payload.payload,
      };
    case types.setWarrantyDateEnd:
      return {
        ...state,
        endDateWarranty: action.date,
        // warranty_activation_list: action.payload.payload,
      };
    // 
    case types.setInitDate:
      return {
        ...state,
        initDate: action.payload,
      };
    case types.setEndDate:
      return {
        ...state,
        endDate: action.payload,
      };
    case types.setWarrantyActivationPlateList:
      return {
        ...state,
        warranty_activation_plate_list: action.payload,
        searchPlateCode: action.searchPlateCode,
        downloadReportActivationsExcel: action?.download ?? false,
        pagination: {
          ...state.pagination,
          plateList: action?.pagination
        }
      };
    case types.setWarrantyActivationDocumentList:
      return {
        ...state,
        warranty_activation_document_list: action.payload,
        searchDocumentCode: action.searchDocumentCode,
        downloadReportActivationsExcel: action?.download ?? false,
        pagination: {
          ...state.pagination,
          documentList: action?.pagination
        }
      };
    case types.setWarrantyActivationMiniCodeList:
      return {
        ...state,
        warranty_activation_minicode_list: action.payload,
        searchMiniCode: action.searchMiniCode,
        downloadReportActivationsExcel: action?.download ?? false,
        pagination: {
          ...state.pagination,
          miniCodeList: action?.pagination
        }
      };
    case types.setUpdateVersionApp:
      return {
        ...state,
        updateVersionApp: action.payload,
      };

    case types.setCorpBatteryList:
      return {
        ...state,
        batteryCorpList: action.payload.dataWarranty,
        selectData: action.payload.selectData,
        clientAddress: action.payload.clientAddress,
        searchCode: action.payload.searchCodes,
        inputSelectCountry: {},
        objAddressSucursal: {},
        selectedAddressSucursal: false,

        // mantenimiento
        fetch: false,
        openAddress: false,
        openMaintenance: false,
        updateVersionApp: false
      };
    case types.setSelectOptionsData:
      return {
        ...state,
        selectData: { ...action.payload }
      };
      break;

    default:
      return state;
  }
};

export { WarrantyReducer, types };
