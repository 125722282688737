import React from 'react';


const types = {
  open: "OPEN",
  open_clear: "OPEN_CLEAR",
  close: "CLOSE",
  change: "CHANGE",
  change_tab: "CHANGE_TAB",
  change_warranty_tab: "CHANGE_WARRANTY_TAB",
  change_new_warranty_tab: "CHANGE_NEW_WARRANTY_TAB",
  validTypeNewWarranty: "VALID_TYPE_NEW_WARRANTY"
};

const DialogReducer = (state, action) => {
  switch (action.type) {
    case types.open:
      return {
        ...state,
        dialog: true,
        active_dialog: action.payload,
        active_new_warranty_tab: action.activeTab,
        validTypeNewWarranty: action.validType,
        rewardDataCart: action.dataCart || {},
        updateVersionApp: true
      };
    case types.open_clear:
      return {
        ...state,
        dialog: true,
        active_dialog: action.payload,
        selected_client: {},
        warranty: {},
        battery: {},
        maintenance: {},

      };
    case types.close:
      return {
        ...state,
        dialog: false,
        openMaintenance: false,
        active_dialog: "",
        openView: "",
        active_new_warranty_tab: action.activeTab,
        validTypeNewWarranty: "",
        updateVersionApp: true
      };
    case types.change:
      return {
        ...state,
        dialog: false,
        active_pane: action.payload,
        active_dialog: "",
        validTypeNewWarranty: "",
        updateVersionApp: !(action?.updateVersion ?? false)
      };
    case types.change_tab:
      return {
        ...state,
        active_tab: action.payload,
      };
    case types.change_warranty_tab:
      return {
        ...state,
        active_warranty_tab: action.payload,
        pagination: {},
        warranty_activation_list: [],
        warranty_activation_plate_list: [],
        warranty_activation_document_list: [],
        warranty_activation_minicode_list: [],
        downloadReportActivationsExcel: false,
        searchPlateCode: "",
        searchDocumentCode: "",
        searchMiniCode: "",
      };
    case types.change_new_warranty_tab:
      return {
        ...state,
        active_new_warranty_tab: action.payload,
      };
    default:
      return state;
  }
};

export { DialogReducer, types };