import * as React from "react";
import { ClientCard } from "../App/ClientCard";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CheckIcon from "@mui/icons-material/Check";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Button from "@material-ui/core/Button";
import { StoreContext } from "../../store/StoreProvider";
import { MultiInput } from "../MultiInput";
import { AddressModal } from "./AddressModal";
import { types } from "../../store/Warranty";
import AppConfig from "../../constants/AppConfig";
import { WarrantyDetail } from "./WarrantyDetail";
import { Alert } from "../Alert";
import { CorpPostWarranty } from "../../store/sagas/Warranty";
import { getI18N } from '../../i18n'

const { validTypes } = AppConfig;

interface NewWarrantyProps {
  callback?: any;
}

function CommentArea() {
  const [store, dispatch] = React.useContext(StoreContext);
  let Mensaje = !!Object.keys(store?.newWarranty).length
    ? store?.newWarranty?.Conversacion[0].Mensaje
    : "";
  const [comment, setComment] = React.useState<string>(Mensaje);

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  return (
    <div className="comment-area">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              className="input-battery"
              id="CommentArea"
              label={i18n.COMMENT}
              sx={{ backgroundColor: "white" }}
              variant="outlined"
              value={comment}
              onChange={(event) => setComment(event.target.value)}
              data-parent="DatosComentario"
              inputProps={{ maxLength: 500 }}
              data-keyword={JSON.stringify({
                Docentry: 0,
                BaseEntry: 0,
                Id: 0,
                Tipo: store?.user?.tipo,
                Mensaje: "",
                UsrReg: store?.user?.user_login,
                FlgEnviado: false,
                FlgLeido: false,
              })}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

const RenderForm = () => {
  const [store, dispatch] = React.useContext(StoreContext);

  if (!!Object.keys(store?.newWarranty).length) {
    return (
      <>
        {store?.newWarranty?.Datos?.map((item: any, i: number) => {
          let placeholder = item?.PlaceHolder;
          if (!!item?.FlgObligatorio) placeholder = `${placeholder} (*)`;

          return (
            <MultiInput
              key={i}
              data={item}
              requred={item?.FlgObligatorio}
              placeholder={placeholder}
              className="input-battery"
              parent="DatosUsuario"
              keyword={JSON.stringify(item)}
            />
          );
        })}
      </>
    );
  }

  const dataWarrantyFront = (store?.userAppCorp) ? store?.battery?.DatosMantenimiento : store?.warrantyFront;
  
  return (
    <>
      {dataWarrantyFront?.map((item: any, i: number) => {
        let placeholder = item?.Placeholder ?? item?.PlaceHolder;
        if (!!item?.FlgObligatorio) placeholder = `${placeholder} (*)`;

        return (
          <MultiInput
            key={i}
            data={item}
            requred={item?.FlgObligatorio}
            placeholder={placeholder}
            className="input-battery"
            parent="DatosUsuario"
            keyword={JSON.stringify(item)}
          />
        );
      })}
    </>
  );
};

export const NewWarranty = (props: NewWarrantyProps) => {
  const [store, dispatch] = React.useContext(StoreContext);
  const [loading, setLoad] = React.useState(false);
  const AlertRef = React.useRef<any>();

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLoad(true);

    let DatosUsuario: any = [],
      DatosComentario: any = [],
      Garantia: any = [],
      Mantenimiento: any = [],
      DatosMantenimiento: any = [];

    for (const key in e.target.elements) {
      if (Object.prototype.hasOwnProperty.call(e.target.elements, key)) {
        const element = e.target.elements[key];
        if (
          !isNaN(Number(key)) &&
          (element.tagName === "INPUT" ||
            (element.tagName !== "FIELDSET" && element.tagName !== "BUTTON"))
        ) {
          if (element.tagName === "INPUT") {
            let originalObj = !!Object.keys(
              element.parentElement.parentElement.dataset
            ).length
              ? element.parentElement.parentElement.dataset
              : !!Object.keys(
                element.parentElement.parentElement.parentElement.dataset
              ).length
                ? element.parentElement.parentElement.parentElement.dataset
                : element.parentElement.dataset;
            if (originalObj.parent === "DatosUsuario") {
              let newObj = JSON.parse(originalObj.keyword);
              newObj["Valor"] = element.value;
              DatosUsuario.push(newObj);
            }
            if (originalObj.parent === "DatosComentario") {
              let newObj = JSON.parse(originalObj.keyword);
              newObj["Mensaje"] = element.value;
              DatosComentario.push(newObj);
            }
          }
        }
      }
    }

    let ObjectRequest: any = {
      DocEntry: 0,
      IdTarjeta: -1,
      IdMiniCod: store?.battery?.IdMiniCod,
      Placa: store?.battery?.Placa,
      CardCode: store?.battery?.CardCode,
      CardName: store?.battery?.CardName,
      FlgAbierto: false,
      // OrigenBat: store?.user?.Country === "PE" ? "NAC" : store?.user?.Country,
      OrigenBat: store?.user?.Country,
      Modelo: store?.battery?.Modelo,
      Direccion: {
        IdDireccion: "",
        Direccion: "",
        Latitud: "",
        Longitud: "",
      },
      IdEstado: 0,
      UserReg: store?.user?.user_login,
      Conversacion: DatosComentario,
      Datos: DatosUsuario,
      TIPO: "SC",
    };

    if (!!Object.keys(store.selected_client).length) {
      
      dispatch({ type: types.openAddressNewWarranty, payload: ObjectRequest });
    } else {
      AlertRef?.current?.open("Debe seleccionar un cliente", "error");
      setLoad(false);
    }
  };

  const disabledPlaca = Boolean(store?.battery?.Placa || (store?.battery?.DatosVehiculo && store?.battery?.DatosVehiculo[0]?.Valor));
  const valuePlacaVehiculo = store?.battery?.Placa || (store?.battery?.DatosVehiculo && store?.battery?.DatosVehiculo[0]?.Valor) || store?.battery?.DatosBateria?.Placa;

  return (
    <div className="new-battery-container">
      <Grid container spacing={1}>
        <Grid item xs={12} style={{ padding: "1rem", background: "#FFFFFF", borderRadius: "15px" }}>
          {store?.openView === validTypes.warrantyDetail ? (
            <>
              <WarrantyDetail />
            </>
          ) : (
            <>
              {(store?.battery?.Id === -1 || store?.battery?.Id === -1) ? (
                <ClientCard callback={() => console.log()} />
              ) : (
                <span style={{ fontSize: 15, fontWeight: "bold" }}>
                  {store?.battery?.CardName}
                </span>
              )}

              <div className="code-battery-container">
                <TextField
                  fullWidth
                  className="input-battery"
                  label={i18n.BATTERY_CODE}
                  variant="outlined"
                  value={store?.battery?.DatosBateria?.Minicodigo ?? store?.battery?.IdMiniCod}
                  disabled={true}
                />
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={1}>
                    <Grid item xs={3}>
                      <div className="battery-detail-container">
                        <span className="title">
                          {(store?.battery?.DatosBateria?.Voltaje ?? store?.battery?.Voltaje) || `0.00`}V
                        </span>
                        <span className="subtitle">{i18n.VOLTAGE}</span>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div className="battery-detail-container">
                        <span className="title">
                          {(store?.battery?.DatosBateria?.CN ?? store?.battery?.CapacidadNominal) || 0}Ah
                        </span>
                        <span className="subtitle">CN</span>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div className="battery-detail-container">
                        <span className="title">
                          {(store?.battery?.DatosBateria?.CCA ?? store?.battery?.CorrienteArranqueEnFrio) || 0}A
                        </span>
                        <span className="subtitle">CCA</span>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <div className="battery-detail-container">
                        <span className="title">
                          {(store?.battery?.DatosBateria?.Densidad || store?.battery?.Densidad) || `0.000`}
                        </span>
                        <span className="subtitle">{i18n.DENSITY}</span>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div className="battery-detail-container">
                        <CheckCircleIcon color="success" />
                      </div>
                    </Grid>
                  </Grid>
                </Box>
                <TextField
                  fullWidth
                  className="input-battery uppercase"
                  label={i18n.BATTERY_MODEL}
                  variant="outlined"
                  value={(store?.battery?.DatosBateria?.Modelo ?? store?.battery?.Modelo)}
                  disabled={true}
                />
                {((store?.battery?.IdMiniCod !== "N" && !store?.userAppCorp) || store?.userAppCorp) && valuePlacaVehiculo && (
                  <TextField
                    fullWidth
                    className="input-battery uppercase"
                    label={i18n.VEHICLE_PLATE}
                    variant="outlined"
                    value={valuePlacaVehiculo ? valuePlacaVehiculo : null}
                    disabled={disabledPlaca}
                  />
                )}
              </div>
              <form action="" onSubmit={handleSubmit}>
                <RenderForm />
                <div id="img-show-container" className="hidden">
                  <img src="" alt="" className="hidden" id="showImg" />
                </div>
                <CommentArea />

                {
                  (store?.user?.Country === "PE" || store?.user?.Country === "NAC") ?
                    (<div style={{ padding: "1rem 2rem 2rem 1rem", textAlign: "justify" }}>
                      {i18n.WARRANTY_INFO}
                    </div>)
                    : <></>
                }

                <div className="button-container">
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={store?.fetch || store.openAddress || loading}
                    type="submit"
                    style={{ borderRadius: "20px", marginBottom: "4rem" }}
                  >
                    <strong>{i18n.REGISTER}</strong>
                  </Button>
                </div>
              </form>

              <AddressModal callback={props.callback} type="WARRANTY" />

            </>
          )}
        </Grid>
      </Grid>

      <Alert ref={AlertRef} />

    </div>
  );
};
