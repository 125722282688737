import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@material-ui/core/Button";
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import { StoreContext } from "../../store/StoreProvider";
import { types } from "../../store/Warranty";
import { types as typesDialog } from "../../store/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';

import {
  getWarranty,
  GetWarrantyRequest,
  WarrantyCard,
  WarrantyRequest,
  WarrantyCardMaintenance,
  CorpPostWarranty,
  CorpPostWarrantyCardMaintenance,
  CorpWarrantyCardById
} from "../../store/sagas/Warranty";
import { bmgSendMessage, clientPointsAddExt } from "../../store/sagas/Clients";
import { Alert } from "../Alert";
import { useDebounce } from "../../hooks/useDebounce";
import Loading from "../Loading";
import zIndex from "@material-ui/core/styles/zIndex";
import { getI18N } from '../../i18n'

interface AddressModalProps {
  callback?: any;
  type?: string;
  onUpdateAddressSucursal?: (address: any, close: boolean) => void;
  openModalSucursal?: boolean;
}
export const AddressModal = (props: AddressModalProps) => {
  const [store, dispatch] = React.useContext(StoreContext);
  const [valueAddress, setValueAddress] = React.useState("");
  const [hide, setHide] = React.useState(false);
  const [objectAddress, setObjectAddress] = React.useState<any>();
  const AlertRef = React.useRef<any>();
  const [loading, setLoad] = React.useState(false);

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  const [disabledButtonRegister, setDisabledButtonRegister] = React.useState(false);

  const setAddressDebounce = useDebounce((response: any) => {
    setLoad(false)
    AlertRef?.current?.open("Registro exitoso", "success");
    dispatch({ type: types.newBattery, payload: response?.Id });
  }, 400);

  const setBatteryDebounce = useDebounce((response: any) => {
    setLoad(false)
    AlertRef?.current?.open("Registro de mantenimiento exitoso", "success");
    dispatch({ type: types.closeAddressMaintenance, payload: response });
  }, 100);

  const setCorpBatteryDebounce = useDebounce((response: any) => {
    setLoad(false)
    AlertRef?.current?.open("Registro de mantenimiento exitoso", "success");
    dispatch({ type: types.setCorpBatteryList, payload: response });
  }, 100);

  const setWarrantyDebounce = useDebounce((response: any) => {
    setLoad(false)

    AlertRef?.current?.open("Registro exitoso", "success");
    dispatch({ type: types.setWarrantyDetailList, payload: response.res, warranty_list: response.warranty_list });

    dispatch({ type: typesDialog.close });
    // if (!!props.callback) {
    //   props.callback();
    //   return;
    // }
  }, 400);

  const handleChangeAddress = (event: any) => {
    let address = store?.clientAddress.find(
      (a: any) => `${a.Id} - ${a.Address}` === event.target.value
    );

    setObjectAddress(address);
    setValueAddress(event.target.value);
  };

  const parseCountry = (battery: any) => {
    var newBattery: any = battery

    // 913955579

    if (store?.userAppCorp) return newBattery;

    switch (store?.user?.Country) {
      case "PE":
      case "NAC":

        break;
      case "EC":
        let formatedNumber = newBattery["DatosUsuario"][1]["Valor"];
        if (formatedNumber.indexOf('0') === 0) {
          formatedNumber = formatedNumber.slice(1);
        }
        let numberEc = `593${formatedNumber}`;
        newBattery["DatosUsuario"][1]["Valor"] = numberEc;

        const splitDocumentEC = newBattery["DatosUsuario"][0]["Valor"].split(" ");

        newBattery["DatosUsuario"][0]["Valor"] = `${store?.inputSelectCountry?.ec_tipDoc} ${(splitDocumentEC[1]) ?? newBattery["DatosUsuario"][0]["Valor"]}`;
        break;
      case "VE":
        let number = `${store?.inputSelectCountry?.ve_codePhone.slice(0)} ${newBattery["DatosUsuario"][1]["Valor"]}`

        const splitDocumentVE = store?.inputSelectCountry?.ve_docNac.split(" ");
        newBattery["DatosUsuario"][0]["Valor"] = `${store?.inputSelectCountry?.ve_tipDoc} ${(splitDocumentVE[1]) ?? store?.inputSelectCountry?.ve_docNac}`
        newBattery["DatosUsuario"][1]["Valor"] = `58${number}`
        break;
      case "CL":

        const splitDocumentCL = newBattery["DatosUsuario"][0]["Valor"].split(" ");
        let numphone = newBattery["DatosUsuario"][1]["Valor"];
        let numberPhoneCL = numphone.length >= 11 ? numphone.substr(2, numphone.length) : numphone;

        newBattery["DatosUsuario"][0]["Valor"] = `${store?.inputSelectCountry?.ch_tipDoc} ${(splitDocumentCL[1]) ?? newBattery["DatosUsuario"][0]["Valor"]}`
        newBattery["DatosUsuario"][1]["Valor"] = `56${numberPhoneCL}`;
        break;

      case "DO":
        let numberDO = `${store?.inputSelectCountry?.codePhone.slice(0)} ${newBattery["DatosUsuario"][1]["Valor"]}`

        const splitDocumentDO = store?.inputSelectCountry?.numDocNac.split(" ");
        newBattery["DatosUsuario"][0]["Valor"] = `${store?.inputSelectCountry?.do_tipDoc} ${(splitDocumentDO[1]) ?? store?.inputSelectCountry?.numDocNac}`
        newBattery["DatosUsuario"][1]["Valor"] = `1${numberDO}`
        break;
      case "BO":
        let numberBO = newBattery["DatosUsuario"][1]["Valor"];
        const numDocBO = newBattery["DatosUsuario"][0]["Valor"];

        newBattery["DatosUsuario"][0]["Valor"] = `${store?.inputSelectCountry?.do_tipDoc} ${numDocBO}`
        newBattery["DatosUsuario"][1]["Valor"] = `591 ${numberBO}`
        break;
      case "TT":
        let numberTT = newBattery["DatosUsuario"][1]["Valor"];
        // const numDocTT = newBattery["DatosUsuario"][0]["Valor"];

        // newBattery["DatosUsuario"][0]["Valor"] = `${store?.inputSelectCountry?.do_tipDoc} ${numDocTT}`
        newBattery["DatosUsuario"][1]["Valor"] = `1868 ${numberTT}`
        break;
      default:
        return <></>;
        break;
    }

    return newBattery
  }

  const send = async () => {
    new Promise((resolve, reject) => {
      // dispatch({ type: types.startFetch });

      let err = false
      if (!!props?.type && props?.type === "WARRANTY") {
        if (!!objectAddress) {
          let { newWarranty } = store;

          newWarranty["Direccion"] = {
            Direccion: objectAddress.Address,
            IdDireccion: objectAddress.Id,
            Latitud: objectAddress.Latitud,
            Longitud: objectAddress.Longitud,
          };

          if (
            store?.newWarranty?.IdTarjeta === -1 ||
            store?.newWarranty?.Id === -1
          ) {
            newWarranty["CardCode"] = store?.selected_client?.Id;
            newWarranty["CardName"] = store?.selected_client?.RzSocial;
            newWarranty["CelularEmpresa"] = store?.selected_client?.Phone;
            newWarranty["EmailEmpresa"] = store?.selected_client?.Email;
          } else {
            newWarranty["CardCode"] = store?.battery?.CardCode;
            newWarranty["CardName"] = store?.battery?.CardName;
            newWarranty["CelularEmpresa"] = store?.battery?.CelularEmpresa;
            newWarranty["EmailEmpresa"] = store?.battery?.EmailEmpresa;

          }
          newWarranty["UserReg"] = store?.user?.user_login;

          if (store?.newWarranty?.IdMiniCod !== "") {
            newWarranty["IdTarjeta"] = (!!store?.battery?.Id)
              ? store?.battery?.Id
              : -1;
          }

          setDisabledButtonRegister(true);

          if (store?.userAppCorp) {

            let { battery } = store;
            let newBatteryCorp: any = (newWarranty?.TIPO !== "SS" ? battery : {})

            newBatteryCorp['Modelo'] = newWarranty['Modelo'];

            if (newWarranty?.TIPO !== "SS") {
              newBatteryCorp['Modelo'] = store?.battery?.DatosBateria?.Modelo;
              newBatteryCorp['MiniCodigo'] = store?.battery?.DatosBateria?.Minicodigo;
              newBatteryCorp['ItemCode'] = store?.battery?.DatosBateria?.CodBateria;
              newBatteryCorp['ItemName'] = store?.battery?.DatosBateria?.NomBateria;
            }

            newBatteryCorp['CardCode'] = store?.selected_client?.Id;
            newBatteryCorp["CardName"] = store?.selected_client?.RzSocial;
            newBatteryCorp["CodUserReg"] = store?.user?.user_login;
            newBatteryCorp["TipoUserReg"] = store?.user?.tipo;
            newBatteryCorp["NomUserReg"] = store?.user?.name;
            newBatteryCorp['OrigenBat'] = store?.user?.Country;

            newBatteryCorp['Comentario'] = newWarranty?.Conversacion[0]['Mensaje'];
            newBatteryCorp['DatosMantenimiento'] = newWarranty?.Datos;

            newBatteryCorp['Direccion'] = objectAddress.Address;
            newBatteryCorp['IdDireccion'] = objectAddress.Id;
            newBatteryCorp['Latitud'] = objectAddress.Latitud;
            newBatteryCorp['Longitud'] = objectAddress.Longitud;

            new Promise((resolve, reject) => {
              CorpPostWarranty(newBatteryCorp).then((response) => {
                setDisabledButtonRegister(false);
                setLoad(false);

                if (response.statusCode !== 200) {
                  AlertRef?.current?.open(response?.message ?? "Ocurrio un error en el servidor", "error");
                }

                if (response.statusCode === 200) {
                  if (response.size > 0) {
                    AlertRef?.current?.open("Registro exitoso", "success");

                    if (store?.selected_client?.Id?.slice(0, 1) === "C") {
                      clientPointsAddExt({ cardCode: store?.user?.user_login/* store?.selected_client?.UserId */, puntos: 1 });
                    }

                    dispatch({ type: types.setWarrantyDetailList, payload: response.value });
                    setTimeout(() => {
                      dispatch({ type: typesDialog.close, activeTab: true });
                    }, 500)
                  }
                  else {
                    AlertRef?.current?.open(response?.message ?? "Ocurrio un error en el servidor.", "error");
                  }
                }
              })
            })

            return;
          }

          WarrantyRequest(newWarranty)
            .then((response) => {
              
              if (response?.Id === -1) {
                setDisabledButtonRegister(false);
                AlertRef?.current?.open("Error al insertar el registro", "error");
                return;
              }

              newWarranty["IdTarjeta"] = response?.Id;

              if (response?.Id > 0 && store?.selected_client?.Id?.slice(0, 1) === "C") {
                clientPointsAddExt({ cardCode: store?.user?.user_login/* store?.selected_client?.UserId */, puntos: 1 });
              }

              // AlertRef?.current?.open("Registro exitoso", "success");

              if (store?.newWarranty?.IdMiniCod !== "N") {
                let idMiniCod = !!newWarranty["IdMiniCod"]
                  ? newWarranty["IdMiniCod"]
                  : `D${newWarranty["IdTarjeta"]}`;

                let obj = {
                  idTarjeta: -1,
                  idMiniCod: idMiniCod,
                  cardCode: store?.selected_client.Id,
                };

                GetWarrantyRequest(obj).then((res: any) => {
                  if (!!res.length) {
                    let obj = {
                      idTarjeta: -1,
                      idMiniCod: -1,
                      cardCode: store?.selected_client.Id,
                    };
                    GetWarrantyRequest(obj).then((warranty_list: any) => {

                      if (!!warranty_list.length) {
                        // AlertRef?.current?.open("Registro exitoso", "success");

                        setWarrantyDebounce({
                          newWarranty,
                          res: res[0],
                          warranty_list,
                        });

                        return;
                      } else {
                        setLoad(false);
                        AlertRef?.current?.open(
                          "No se encontro garantias",
                          "error"
                        );
                      }
                    });
                  } else {
                    setLoad(false);
                    AlertRef?.current?.open(
                      "No se encontro la garantia",
                      "error"
                    );
                  }
                });
              } else {
                let obj = {
                  idTarjeta: -1,
                  idMiniCod: -1,
                  cardCode: store?.selected_client.Id,
                };

                GetWarrantyRequest(obj).then((warranty_list: any) => {

                  if (!!warranty_list.length) {
                    AlertRef?.current?.open("Registro exitoso", "success");
                    setWarrantyDebounce({
                      newWarranty,
                      res: newWarranty,
                      warranty_list,
                    });
                  } else {
                    setLoad(false);
                    AlertRef?.current?.open(
                      "No se encontro garantias",
                      "error"
                    );
                  }
                });
              }
            })
            .catch((error) => {
              setLoad(false);
              AlertRef?.current?.open("Error en el servidor", "error");
              return;
            });
        } else {
          err = true
          AlertRef?.current?.open(
            "Debe seleccionar una dirección de cliente",
            "error"
          );
        }

      } else {

        let { battery } = store;

        if (!!objectAddress) {
          battery["DireccionEmision"] = {
            Departamento: "",
            Direccion: objectAddress.Address,
            IdDireccion: objectAddress.Id,
            Latitud: objectAddress.Latitud,
            Longitud: objectAddress.Longitud,
            Provincia: "",
            Ubigeo: "",
          };

          // Agregado bmg
          battery["OrigenBat"] = store?.user?.Country;

          battery["CardCode"] = store?.selected_client?.Id;
          battery["CelularEmpresa"] = store?.selected_client?.Phone;
          battery["EmailEmpresa"] = store?.selected_client?.Email;
          battery["CardName"] = store?.selected_client?.RzSocial;
          battery["UserReg"] = store?.user?.user_login;

          setDisabledButtonRegister(true);

          if (!!props?.type && props?.type === "MAINTENANCE") {

            if (store?.userAppCorp) {

              let { battery } = store;

              const objMaintenancePost = {
                // oDatosMantenimiento: {
                IdTarjeta: battery["IdTarjeta"],
                DatosMantenimiento: battery["DatosMantenimiento"][0]?.Datos,
                DireccionMantenimiento: {
                  FecMantenimiento: battery["DatosMantenimiento"][0]["FecReg"],
                  UsrReg: store?.user?.user_login,
                  IdDireccion: objectAddress.Id,
                  Direccion: objectAddress.Address,
                  Latitud: objectAddress.Latitud,
                  Longitud: objectAddress.Longitud
                }
                // }
              }
              // console.log(objMaintenancePost,disabledButtonRegister, loading)
              CorpPostWarrantyCardMaintenance(objMaintenancePost).then((response) => {
                if (response.statusCode !== 200) {
                  setDisabledButtonRegister(false);
                  AlertRef?.current?.open(response?.message ?? "Ocurrio un error en el servidor", "error");
                }

                if (response.statusCode === 200) {
                  if (response.size > 0) {

                    let objReq = {
                      idTarjetaGarantia: objMaintenancePost?.IdTarjeta
                    };

                    CorpWarrantyCardById(objReq).then((data) => {

                      setDisabledButtonRegister(false);
                      setLoad(false);

                      if (data.statusCode === 200) {

                        AlertRef?.current?.open("Registro de mantenimiento exitoso", "success");

                        let objSend = {
                          clientAddress: store?.clientAddress,
                          dataWarranty: data.value,
                          selectData: store?.selectData,
                          searchCodes: store?.searchCode,
                        };

                        setCorpBatteryDebounce(objSend);
                        // props.callback();
                      }
                    })
                  }
                }
              })

              return;
            }

            battery["DatosMantenimiento"][0]["Direccion"] = objectAddress.Address;
            battery["DatosMantenimiento"][0]["IdDireccion"] = objectAddress.Id;
            battery["DatosMantenimiento"][0]["Latitud"] = objectAddress.Latitud;
            battery["DatosMantenimiento"][0]["Longitud"] = objectAddress.Longitud;
            battery["DatosMantenimiento"][0]["UserReg"] = store?.user?.user_login;
            battery["DatosMantenimiento"][0]["UsrReg"] = store?.user?.user_login;

            WarrantyCardMaintenance({ data: battery["DatosMantenimiento"][0], id: battery["Id"] })
              .then(() => {

                let objReq = {
                  idTarjeta: battery?.Id,
                  qrCode: battery?.IdMiniCod,
                  placa: "",
                  ruc: "",
                  // cardCode: battery?.CardCode,
                  origenBat: store?.user?.Country,
                  //battery?.OrigenBat,
                };

                getWarranty(objReq).then((dataWarranty) => {
                  if (dataWarranty[0]) {
                    setBatteryDebounce(dataWarranty[0]);
                  }

                  props.callback();
                });
              })
              .catch((error) => {
                setDisabledButtonRegister(false);
                setLoad(false);
                AlertRef?.current?.open("Error en el servidor", "error");
                return;
              });

          } else {
            
            err = true;
            let newCountryBattery = parseCountry(battery);
            WarrantyCard(newCountryBattery)
              .then(async (response) => {

                if (!response?.ErrorMessage) {

                  clientPointsAddExt({ cardCode: store?.user?.user_login/* store?.selected_client?.UserId */, puntos: 1 });

                  /*const codeCountryMsg = {
                    "NAC": "51",
                    "PE": "51",
                    // "VE": "58",
                    // "CL": "56",
                    // "EC": "593",
                    // "DO": "1"
                  };
                  
                  try {
                    // const numberClient = newCountryBattery["DatosUsuario"][1]["Valor"];

                    let country: keyof typeof codeCountryMsg = store?.user?.Country;

                    const clientPhone = `${(codeCountryMsg[country] ?? "")}${numberClient}`;

                    const clientName = newCountryBattery["DatosUsuario"]?.[3]["Valor"];
                    const codeBattery = newCountryBattery['IdMiniCod'];

                    if (clientPhone?.trim().length > 2) {
                      const dataRequestMessage = {
                        phoneClient: clientPhone?.replace(/\s/g, '')?.trim(),
                        paramsMsg: [
                          clientName?.toUpperCase(), codeBattery, codeBattery
                        ]
                      }

                      bmgSendMessage(dataRequestMessage).then(() => { });
                    } 
                  } catch (error) {
                    console.error(error);
                  }*/

                  setAddressDebounce(response);
                } else {
                  setDisabledButtonRegister(false);
                  setLoad(false);
                  reject(response?.ErrorMessage);
                  setHide(false);
                  AlertRef?.current?.open("Error al insertar registro", "error");
                }
                // props.callback();
              })
              .catch((error) => {
                setLoad(false);
                setHide(false);
                AlertRef?.current?.open("Error en el servidor", "error");
                return;
              });
          }

        } else {
          reject("Debe seleccionar una dirección de cliente");
          err = true;
          AlertRef?.current?.open(
            "Debe seleccionar una dirección de cliente",
            "error"
          );
        }
      }
      /* if (!err) {
        setHide(true)
      } */
    }).catch(err => {
      setHide(false);
      AlertRef?.current?.open(err, "error");
    })

    return
  };

  return (
    <div style={{}}>
      <Dialog open={(store?.userAppCorp && props?.openModalSucursal) ? props?.openModalSucursal : store.openAddress} onClose={() => console.log()} className={!!hide ? "hide-address modal-custom-bmg" : "modal-custom-bmg"}>
        {
          !store?.userAppCorp ? (<DialogTitle>{i18n.CUSTOMER_ADDRESS}:</DialogTitle>) : <></>
        }
        {store?.userAppCorp && !store?.validTypeNewWarranty && !store?.openMaintenance && (<IconButton
          aria-label="close"
          onClick={() => {
            if (!!props.onUpdateAddressSucursal) {
              props.onUpdateAddressSucursal(objectAddress, true);

              return;
            }
            // const sendPayload = {
            //   selected: (store?.objAddressSucursal?.Address ? true : false),
            //   objectAddress: store?.objAddressSucursal
            // }

            // dispatch({ type: types.closeAddress, payload: sendPayload });
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
          style={{ zIndex: 1 }}
        >
          <CloseIcon />
        </IconButton>)}


        <DialogContent>
          <FormControl>
            {
              !store?.userAppCorp ? (
                <FormLabel id="demo-controlled-radio-buttons-group">
                  {i18n.DISPATCH}
                </FormLabel>
              ) : <></>
            }

            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={valueAddress}
              onChange={handleChangeAddress}
            >
              {store.clientAddress
                ?.filter((item: any) => {
                  return (item?.Type && item?.Type.toLocaleUpperCase() === "S") || (item?.Tipo && item?.Tipo.toLocaleUpperCase() === "S");
                })
                ?.map((address: any, i: number) => (
                  <FormControlLabel
                    key={i}
                    value={`${address.Id} - ${address.Address}`}
                    control={<Radio size="small" />}
                    label={`${address.Id} - ${address.Address}`}
                  />
                ))}
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          {(!store.userAppCorp || store?.validTypeNewWarranty || store?.openMaintenance) && (<Button
            onClick={() => {
              dispatch({ type: types.closeAddress });
            }}
          >
            <ChevronLeftRoundedIcon />
            {i18n.GO_BACK}
          </Button>)}

          {store.userAppCorp && !store?.validTypeNewWarranty && !store?.openMaintenance && (<Button onClick={() => {

            if (!objectAddress) {
              AlertRef?.current?.open("Seleccione una dirección", "error");
              return;
            }

            // const sendPayload = {
            //   selected: true,
            //   objectAddress: objectAddress
            // }
            // dispatch({ type: types.closeAddress, payload: sendPayload });
            if (!!props.onUpdateAddressSucursal) {
              props.onUpdateAddressSucursal(objectAddress, false);

              return;
            }
          }}>OK</Button>)}
          {(!store.userAppCorp || store?.validTypeNewWarranty || store?.openMaintenance) && (
            <Button onClick={send} disabled={disabledButtonRegister} className="button-icon-register">
              <Loading loading={disabledButtonRegister}>
                <CheckCircleOutlineRoundedIcon className="green-color-bmg" />
              </Loading>
              <span>{i18n.REGISTER}</span>
            </Button>)}

        </DialogActions>
        <Alert ref={AlertRef} />
      </Dialog>
    </div>
  );
};
