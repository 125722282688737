import * as React from "react";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { resizeImage } from "../../hooks/useImage";
import { fileUpload } from "../../store/sagas/Clients";

interface CameraProps { }

export const Camera = (props: CameraProps) => {

  const onChangeCamera = (e: any) => {
    const file = e.target.files[0];

    if (!file) return;

    const fileExtension = file.name.split('.').pop().toLowerCase();
    const reader = new FileReader();

    reader.onloadend = function () {

      const input: any = window?.document?.getElementById("imgCustomField");
      const div: any = window?.document?.getElementById("img-show-container")
      const img: any = window?.document?.getElementById("showImg");

      const base64String: string = (reader.result as string);
      const byteString: string = base64String ? base64String.split(',')[1] : '';

      const dataRequest = {
        bytes: byteString,
        extension: fileExtension
      }

      fileUpload(dataRequest).then((response) => {
        if (response.status === 'ok') {
          img.src = response.data;
          input.value = response.data;
          img.className = "show img-responsive img-upload";
          div.className = "show img-show-container";
        }
      })
    };

    reader.readAsDataURL(file);
  };

  const __onChangeCamera = (e: any) => {
    var file = e.target.files[0];

    if (!file ) return;

    var reader = new FileReader();
    reader.onloadend = function () {
      let input: any = window?.document?.getElementById("imgCustomField");
      let div: any = window?.document?.getElementById("img-show-container")
      let img: any = window?.document?.getElementById("showImg");
      if (!!input && !!div && !!img) {
        resizeImage(reader.result, 180, 130).then((result) => {
          img.src = result;
          input.value = result;
          img.className = "show img-responsive img-upload";
          div.className = "show img-show-container";
        });

      }
    };
    reader.readAsDataURL(file);

  };


  return (
    <div>
      <label htmlFor="avatar">
        <AddAPhotoIcon sx={{ color: "#000000" }} />
      </label>
      <input
        type="file"
        accept="image/*"
        name="avatar"
        id="avatar"
        className="hidden"
        onChange={onChangeCamera}
      />
    </div>
  );
};
