import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
// import SearchIcon from "@mui/icons-material/Search";
// import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { Scanner } from "./Scanner";
import {
  clientAddress,
  getWarranty,
  getWarrantyById,
  GetWarrantyRequest,
  WarrantyRequest,
  WarrantyRequestParams,
  getCorpWarrantyMinicod,
  CorpMarcaModeloVehiculos,
  CorpClientAddress,
  getCorpFrecuenciaGarantia,
  CorpGetWarrantyByMinicod,
  CorpGetDetailByMinicod

} from "../../store/sagas/Warranty";
import { Alert } from "../Alert";
import { StoreContext } from "../../store/StoreProvider";
import { types } from "../../store/Warranty";
import { types as typesDialog } from "../../store/Dialog";
import { Dialog } from "../App/Dialog";
import { ClientList } from "../App/ClientList";
import { NewBattery } from "./NewBattery";
import { ClientCard } from "../App/ClientCard";

import { CombustionV, MarModeloV, TipoUsoV } from "../../store/sagas/Warranty";
import Loading from "../Loading";
import { BatteryListModal } from "./BatteryListModal";
import { NewWarranty } from "./NewWarranty";
import { Camera } from "../App/Camera";
import Typography from "@mui/material/Typography";
import AppConfig from "../../constants/AppConfig";
import { AddWarranty } from "../icons/AddWarranty";
import { IconSearch } from "../icons/IconSearch";
import { IconQr } from "../icons/IconQr";
import { Bottom } from "../App/Bottom";
import AddIcon from '@mui/icons-material/Add';
// import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { IconShip } from "../icons/IconShip";
import { IconBoat } from "../icons/IconBoat";
import { IconTrash } from "../icons/IconTrash";

// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControl from '@mui/material/FormControl';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabPanel } from "./TabPanel";
import DeleteIcon from '@mui/icons-material/Delete';
import Container from '@mui/material/Container';
import { Button } from "@material-ui/core";
import { getI18N } from '../../i18n'

interface TabOneProps {
  callback?: any;
  type?: string;
}

const ACTIVE_DIALOG = "new_code";
const { validTypes } = AppConfig;

export const TabOne = (props: TabOneProps) => {
  const [scan_qr, setScan] = React.useState(false);
  const [loading, setLoad] = React.useState(false);
  const [loadingPlate, setLoadPlate] = React.useState(false);
  const [loadingDoc, setLoadDoc] = React.useState(false);
  const [batteryList, setBatteryList] = React.useState([]);
  const [showBatteryList, setShowBatteryList] = React.useState(false);
  const [plateCode, setPlate] = React.useState("");
  const [documentCode, setDoc] = React.useState("");
  const [store, dispatch] = React.useContext(StoreContext);
  const [origen, setOrigen] = React.useState(
    // store?.user?.Country == "PE" ? "NAC" : store?.user?.Country
    store?.user?.Country
  );
  // /*store?.searchCode || */
  const [searchCode, setCode] = React.useState("");

  // corp
  const [loadingCodeCorp, setLoadingCodeCorp] = React.useState(false);
  const [loadingQrList, setLoadingQrList] = React.useState(false);
  const [typeCorpAction, setTypeCorpAction] = React.useState(0);
  const [codeCorpShipList, setCodeCorpShipList] = React.useState<string[]>([]);
  const [codeCorpBoatList, setCodeCorpBoatList] = React.useState<string[]>([]);
  // 

  const AlertRef = React.useRef<any>();
  const BatteryRef = React.useRef<any>();

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  // let placeHolderSearch = "";

  /*switch (store?.user?.Country) {
    case "PE":
    case "NAC":
      placeHolderSearch = "DNI / RUC";
      break;
    case "VE":
      placeHolderSearch = "CI / RIF";
      break;
    case "CL":
      placeHolderSearch = "RUT";
      break;
    case "EC":
      placeHolderSearch = "CI / RUC / CE";
      break;
    case "DO":
      placeHolderSearch = "CI / RNC";
      break;
  }*/

  const toggleClient = () => {
    if (!store?.openView || store?.openView === validTypes.battery) {
      BatteryRef?.current?.saveBattery().then((battery: any) => {
        dispatch({ type: types.setViewClient, payload: battery });
      });
    } else {
      dispatch({ type: types.setViewDefault });
    }
  };

  const findById = async (res: any = false, type: string = "") => {

    let loadingCode = false;

    let obj = {
      qrCode: res || searchCode,
      placa: !!res ? "" : plateCode,
      ruc: !!res ? "" : documentCode,
      CardCode: !!Object.keys(store.selected_client).length
        ? store.selected_client.Id
        : "",
      origenBat: origen,
      tipoConsulta: props?.type === "SC" ? 1 : 0,
    };

    if (loading === true || loadingPlate === true || loadingDoc === true || loadingQrList === true) {
      AlertRef?.current?.open("Se esta ejecutando una busqueda", "error");
      return;
    }
  
    if (!!type) {

      if (type === "code") {
        if (!searchCode) {
          AlertRef?.current?.open("Ingrese un código de barras", "error");
          loadingCode = false;
          setLoad(false);
          return;
        }

        loadingCode = true;
        setLoad(true);
        
        obj.placa = "";
        obj.ruc = "";

        if( store?.userAppCorp && !props?.type){
          const objRequest = {
            QRcode: searchCode?.toLocaleUpperCase(),
            OrigenBat: store?.user?.Country
          }
      
          await CorpGetDetailByMinicod(objRequest).then((response) => {
      
            if (response?.statusCode !== 200) {
              setLoad(false);
              loadingCode = false;
              return AlertRef?.current?.open(response?.message ?? "Ocurrio un error al validar el codigo", "error");
            }
      
            if (response?.statusCode === 200) {
              
            }
          })
        }
      }
      
      if (type === "plate") {
        setLoadPlate(true);
        obj.qrCode = "";
        obj.ruc = "";
      }
      if (type === "document") {
        setLoadDoc(true);
        obj.qrCode = "";
        obj.placa = "";
      }
      if (type === "codeList") {
        setLoadingQrList(true);
      }
    } else {
      loadingCode = true;
      setLoad(true);
    }

    if (props?.type === "SC") {

      if (store?.userAppCorp) {

        if (store.openCreateWarrantyRequest) {
          const objCorp = {
            QRcode: obj.qrCode?.toUpperCase(),
            OrigenBat: origen
          }

          CorpGetWarrantyByMinicod(objCorp).then((response) => {

            setLoad(false);
            setLoadPlate(false);
            setLoadDoc(false);

            if (response.statusCode !== 200) {
              return AlertRef?.current?.open(response?.message ?? "Codigo no encontrado", "error");
            }

            if (response.statusCode === 200) {

              if (response.size <= 0) {
                AlertRef?.current?.open(response?.message ?? "Codigo no encontrado", "error");
              }

              if (response.size > 0) {
                let res = {
                  battery: response.value,
                  // warrantyFront: dataFormWarranty,
                };
                dispatch({
                  type: types.setWarrantyData,
                  payload: res,
                });

                if (!!props.callback) {
                  props.callback();
                  return;
                }
              }
            }
          })
            .catch((error) => {
              setLoad(false);
              setLoadPlate(false);
              setLoadDoc(false);
              AlertRef?.current?.open("Error en el servidor", "error");
            });
        }

        return;
      }

      WarrantyRequestParams({OrigenBat: origen})
        .then((dataFormWarranty: any) => {
          getWarrantyById(obj)
            .then((data) => {
              if (!!data.length) {
                let {
                  CodBateria,
                  Documento,
                  IdTarjetaGarantia,
                  Marca,
                  Modelo,
                  Placa,
                  TieneTarjeta,
                  TipoDoc,
                } = data[0];
                let obj = {
                  idTarjeta: IdTarjetaGarantia,
                  qrCode: CodBateria,
                  placa: "",
                  ruc: "",
                  cardCode: Documento,
                  origenBat: origen,
                };
                getWarranty(obj).then((dataWarranty: any) => {
                  let res = {
                    battery: dataWarranty[0],
                    warrantyFront: dataFormWarranty,
                  };
                  setLoad(false);
                  setLoadPlate(false);
                  setLoadDoc(false);
                  dispatch({
                    type: types.setWarrantyData,
                    payload: res,
                  });
                  if (!!props.callback) {
                    props.callback();
                    return;
                  }
                });
              }
            })
            .catch((error) => {
              setLoad(false);
              setLoadPlate(false);
              setLoadDoc(false);
              AlertRef?.current?.open("Codigo no encontrado", "error");
            });
        })
        .catch((error) => {
          setLoad(false);
          setLoadPlate(false);
          setLoadDoc(false);
          AlertRef?.current?.open("Error en el servidor", "error");
        });
    } else {

      if (store?.userAppCorp) {
        
        if( type === "code" && !loadingCode ){
          return;
        }

        let arCodeQrList = (typeCorpAction === 0) ? codeCorpShipList : codeCorpBoatList;

        if (type === "codeList" && arCodeQrList.length <= 0) {
          AlertRef?.current?.open(
            "No hay códigos agregados para buscar",
            "error"
          );
          setLoadingQrList(false);
          return;
        }

        const qrCodesToSearch = (type === "codeList") ? arCodeQrList.join(',') : (res || searchCode);

        const objCorp = {
          QRcodes: qrCodesToSearch,
          origenBat: origen
        }

        getCorpWarrantyMinicod(objCorp)
          .then((data) => {

            if (data.statusCode !== 200) {
              setLoad(false);
              setLoadPlate(false);
              setLoadDoc(false);
              setLoadingQrList(false);
              AlertRef?.current?.open(
                data?.message ?? "Los codigos ingresado no pertenecen al SN seleccionado",
                "error"
              );
            }

            if (data.statusCode === 200) {

              // setLoadingQrList(false);
              // setLoad(false);
              // setLoadPlate(false);
              // setLoadDoc(false);

              if (data.size <= 0) {
                AlertRef?.current?.open(
                  data?.message,
                  "error"
                );

                return;
              }

              // const arBatteries = data?.value?.DatosBaterias ?? [];
              const arDataBatteries = data?.value ?? [];

              setCorpBattery(arDataBatteries, qrCodesToSearch);
              // setBatteryList(arBatteries);
              // setShowBatteryList(true);

            }
          })
          .catch((error) => {
            setLoad(false);
            setLoadPlate(false);
            setLoadDoc(false);
            AlertRef?.current?.open("Codigo no encontrado", "error");
          });
        return;
      }

      getWarrantyById(obj)
        .then((data) => {
          if (!!data.length) {
            if (!!data[0]?.DscResponse && !!data[0]?.CodResponse) {
              let msg: string = "Error";
              if (!!data[0]?.DscResponse) {
                msg = data[0]?.DscResponse;
              }
              AlertRef?.current?.open(msg, "error");
              setLoad(false);
              return;
            }

            if (data.length === 1) {
              setBattery(data[0]);
            } else {
              setLoad(false);
              setLoadPlate(false);
              setLoadDoc(false);
              setBatteryList(data);
              setShowBatteryList(true);
            }
          } else {
            setLoad(false);
            setLoadPlate(false);
            setLoadDoc(false);
            AlertRef?.current?.open(
              "El codigo unico ingresado no pertenece al SN seleccionado",
              "error"
            );
          }
        })
        .catch((error) => {
          setLoad(false);
          setLoadPlate(false);
          setLoadDoc(false);
          AlertRef?.current?.open("Codigo no encontrado", "error");
        });
    }
  };

  const open = () => {
    if (
      store?.openView === validTypes.warranty ||
      store?.openView === validTypes.warrantyDetail
    ) {
      dispatch({ type: types.setViewBattery });
      return;
    }

    if (!!props.callback) {
      props.callback();
      return;
    }

    if (!store.dialog) {
      dispatch({ type: typesDialog.open, payload: ACTIVE_DIALOG });
    } else {
      dispatch({ type: typesDialog.close });
    }
  };

  const scanToggle = (e: any) => {
    e.preventDefault();
    setScan(!scan_qr);
  };

  const addCodeBattery = (e: any) => {
    e.preventDefault();

    if (searchCode.trim() === "") {
      return AlertRef?.current?.open("Ingrese un código válido", "error");
    }

    if (typeCorpAction === 0) {

      if( codeCorpShipList?.length >= 10 ){
        return AlertRef?.current?.open("Solo se permiten 10 códigos de baterias", "error");
      }

      const existCode = codeCorpShipList?.findIndex((code) => code === searchCode);

      if (existCode >= 0) {
        return AlertRef?.current?.open("El código ya se encuentra agregado", "error");
      }
    }

    if (typeCorpAction === 1) {

      if( codeCorpBoatList?.length >= 10 ){
        return AlertRef?.current?.open("Solo se permiten 10 códigos de baterias", "error");
      }

      const existCode = codeCorpBoatList?.findIndex((code) => code === searchCode);

      if (existCode >= 0) {
        return AlertRef?.current?.open("El código ya se encuentra agregado", "error");
      }
    }

    const objRequest = {
      QRcode: searchCode?.toLocaleUpperCase(),
      OrigenBat: store?.user?.Country
    }

    setLoadingCodeCorp(true);

    CorpGetDetailByMinicod(objRequest).then((response) => {

      setLoadingCodeCorp(false);

      if (response?.statusCode !== 200) {
        return AlertRef?.current?.open(response?.message ?? "Ocurrio un error al validar el codigo", "error");
      }

      if (response?.statusCode === 200) {
        if (typeCorpAction === 0) {
          setCodeCorpShipList([...codeCorpShipList, (searchCode?.toLocaleUpperCase())]);
        }

        if (typeCorpAction === 1) {
          setCodeCorpBoatList([...codeCorpBoatList, (searchCode?.toLocaleUpperCase())]);
        }
      }
      setCode("");
    })

  };

  const search = (type: string) => {
    findById(false, type);
  };

  const setBattery = (obj: any) => {
    let objReq = {
      idTarjeta: obj?.IdTarjetaGarantia,
      qrCode: obj?.CodBateria,
      placa: "",
      ruc: "",
      cardCode: obj?.Documento,
      // origenBat: store?.user?.Country == "PE" ? "NAC" : store?.user?.Country,
      origenBat: store?.user?.Country,
    };
    getWarranty(objReq).then((dataWarranty: any) => {

      if( !dataWarranty[0] ) {
        return AlertRef?.current?.open(
          "No existe datos de el código ingresado.",
          "error"
        );
      }

      let customUserData = dataWarranty[0]?.DatosUsuario;
      let customCarData = dataWarranty[0]?.DatosVehiculo;

      customUserData[0].Tipo = "INT";
      customUserData[0].Maximo = 11;
      customUserData[0].Minimo = 8;

      customUserData[1].Tipo = "INT";
      customUserData[1].Maximo = 9;
      customUserData[1].Minimo = 8;

      customUserData[2].Tipo = "EMAIL";
      customUserData[3].Tipo = "TXT";

      customCarData[5].FlgObligatorio = false;

      if (
        false
        // !dataWarranty[0]?.DatosUsuario[0].Valor ||
        // !dataWarranty[0]?.DatosUsuario[1].Valor ||
        // !dataWarranty[0]?.DatosUsuario[3].Valor
      ) {
        AlertRef?.current?.open(
          "Debe registrar PRE REGISTRO de la batería para que proceda su garantía.",
          "error"
        );
        setLoad(false);
      } else {
        new Promise(function (resolve, reject) {
          CombustionV({ country: store?.user?.Country }).then((dataComb) => {
            MarModeloV().then((data) => {
              let brands: any = [];
              let models: any = [];
              let dataMarMod = data.filter((d: any) => {
                let res;
                if (brands.indexOf(d.DESMARCA) === -1) {
                  brands.push(d.DESMARCA);
                  res = {
                    CODMARCA: d.CODMARCA,
                    DESMARCA: d.DESMARCA,
                  };
                }
                return res;
              });

              let dataMod = data.filter((d: any) => {
                let res;
                if (models.indexOf(d.DESMODELO) === -1) {
                  models.push(d.DESMODELO);
                  res = {
                    CODMODELO: d.CODMODELO,
                    DESMODELO: d.DESMODELO,
                  };
                }
                return res;
              });

              TipoUsoV({ country: store?.user?.Country }).then((dataTipo) => {
                clientAddress({
                  key: store?.selected_client?.Id,
                }).then((address) => {
                  let newObj: any = {
                    ...dataWarranty[0],
                    TienePreTarjeta: obj?.TienePreTarjeta,
                    DatosUsuario: customUserData,
                    DatosVehiculo: customCarData,
                  };

                  let objSend = {
                    clientAddress: address,
                    selectData: {
                      CombustionV: dataComb,
                      MarModeloV: dataMarMod,
                      ModeloV: dataMod,
                      TipoUsoV: dataTipo,
                    },
                    dataWarranty: newObj,
                    searchCode,
                  };
                  resolve(dispatch({ type: types.setBattery, payload: objSend }));
                });
              });
            });
          });
        }).then(() => {
          setTimeout(() => {
            // setLoad(false);
            dispatch({
              type: typesDialog.open,
              payload: ACTIVE_DIALOG,
            });
          }, 500);
        });
      }
    });
  };

  const setCorpBattery = (obj: any, searchCodeList: string) => {

    // let objSend = {
    //   dataWarranty: obj,
    //   // searchCode,
    // };
    // dispatch({ type: types.setCorpBatteryList, payload: objSend })

    new Promise(function (resolve, reject) {
      CorpMarcaModeloVehiculos().then((data) => {
        const arMarcas = data?.value?.Marca;
        const arModelos = data?.value?.Modelo;

        const paramsRequest = {
          CodCliente: store?.selected_client?.Id
        }

        CorpClientAddress(paramsRequest).then((data) => {
          const clientAddress = data.value;
          getCorpFrecuenciaGarantia().then((data) => {
            let objSend = {
              clientAddress: clientAddress,
              dataWarranty: obj,
              selectData: {
                TiempoGarantia: data?.value?.TiempoGarantia,
                MarModeloV: arMarcas,
                ModeloV: arModelos,
                PeriodoRevision: data?.value?.PeriodoRevision
              },
              searchCodes: searchCodeList,
            };

            resolve(dispatch({ type: types.setCorpBatteryList, payload: objSend }));
          })
        })

      })
    }).then(() => {
      setTimeout(() => {
        dispatch({
          type: typesDialog.open,
          payload: ACTIVE_DIALOG,
        });
      }, 500);
    })


    // dispatch({
    //   type: typesDialog.open,
    //   payload: ACTIVE_DIALOG,
    // });
  };

  const showWarranty = () => {
    if (store?.battery?.Id === -1) {
      AlertRef?.current?.open("Debe registrar primero la bateria", "error");
    } else {
      let obj = {
        idTarjeta: store?.battery?.Id,
        idMiniCod: store?.battery?.Id,
        cardCode: store?.battery?.CardCode,
      };

      WarrantyRequestParams().then((warrantyFront) => {
        GetWarrantyRequest(obj).then((warrantyData) => {
          if (!!warrantyData.length) {
            dispatch({
              type: types.setWarrantyDetail,
              payload: warrantyData[0],
            });
          } else {
            dispatch({ type: types.setViewWarranty, payload: warrantyFront });
          }
        });
      });
    }
  };

  const SwitchView = () => {
    switch (store?.openView) {
      case validTypes.client:
        return <ClientList callback={toggleClient} />;
        break;
      case validTypes.warranty:
        return <NewWarranty callback={() => console.log()} />;
        break;
      case validTypes.warrantyDetail:
        return <NewWarranty callback={() => console.log()} />;
        break;
      case validTypes.battery:
        return <NewBattery ref={BatteryRef} callback={open} />;
        break;

      default:
        return <NewBattery ref={BatteryRef} callback={open} />;
        break;
    }
  };

  const RenderIcon = () => {
    switch (store?.openView) {
      case validTypes.battery:
        return (
          <IconButton
            style={{ color: "#000" }}
            sx={{ p: "10px" }}
            aria-label="Nueva garantia"
            onClick={showWarranty}
          >
            <AddWarranty />
          </IconButton>
        );
        break;
      case validTypes.warranty:
        return <Camera />;
        break;
      case validTypes.warrantyDetail:
        return <></>;
        break;

      default:
        return (
          <IconButton
            style={{ color: "#000" }}
            sx={{ p: "10px" }}
            aria-label="Nueva garantia"
            onClick={showWarranty}
          >
            <AddWarranty />
          </IconButton>
        );
        break;
    }
  };

  const RenderTitle = () => {
    let title: any;

    switch (store?.openView) {
      case validTypes.battery:
        title = store?.battery?.CardName || (
          <ClientCard callback={toggleClient} />
        );
        break;
      case validTypes.warranty:
        title = "Solicitud de garantia";
        break;
      case validTypes.warrantyDetail:
        title = "Detalle de solicitud de garantia";
        break;

      default:
        title = store?.battery?.CardName || (
          <ClientCard callback={toggleClient} />
        );
        break;
    }

    if (typeof title !== "string") {
      return <>{title}</>;
    } else {
      return (
        <Typography
          className="cardName-title"
          sx={{ ml: 2, flex: 1 }}
          variant="h6"
          component="div"
          align="center"
          style={{ color: "#000" }}
        >
          {title}
        </Typography>
      );
    }
  };

  const RenderCorpAction = () => {
    return (
      <>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1} className="container-action-corp" style={{ marginLeft: "1rem" }}>

            <Tabs value={typeCorpAction} onChange={(event: React.SyntheticEvent, newValue: number) => {
              setTypeCorpAction(newValue);
            }} aria-label="icon tabs corp">
              <Tab className="tab-action-corp" icon={<IconShip />} aria-label="ship" />
              <Tab className="tab-action-corp" icon={<IconBoat />} aria-label="boat" />
            </Tabs>
          </Grid>
        </Box >
      </>
    )
  }

  const RenderCorpShipList = () => {
    const handleRemoveCodeShipt = (index: number) => {
      const newElements = codeCorpShipList.filter((_, i) => i !== index);
      setCodeCorpShipList(newElements);
    };

    return (
      <>
        <Box sx={{ flexGrow: 1 }} className="container-body-action-corp">
          <Paper variant="outlined" style={{ borderColor: "#000000", borderRadius: "15px" }}>
            {
              codeCorpShipList?.map((codeItem, index: number) => {
                return (
                  <Box sx={{ flexGrow: 1 }} className="item-codigo" key={index}>
                    <Grid container spacing={2} columns={16} justifyContent="start" alignItems="center">
                      <Grid item xs={12}>
                        <h3 style={{ margin: "0" }}>{codeItem}</h3>
                      </Grid>
                      <Grid item xs={4}>
                        <IconButton
                          onClick={() => {
                            handleRemoveCodeShipt(index)
                          }}
                          sx={{ p: "10px" }}
                          aria-label=""
                          disableFocusRipple={true}
                          style={{ color: "#000" }}
                          color="primary"
                        >
                          <IconTrash />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Box>
                )
              })
            }
          </Paper>
        </Box>
      </>
    )
  }

  const RenderCorpBoatList = () => {
    const handleRemoveCodeBoat = (index: number) => {
      const newElements = codeCorpBoatList.filter((_, i) => i !== index);
      setCodeCorpBoatList(newElements);
    };
    return (
      <Box sx={{ flexGrow: 1 }} className="container-body-action-corp">
        <Paper variant="outlined" style={{ borderColor: "#000000", borderRadius: "15px" }}>
          {
            codeCorpBoatList?.map((codeItem, index: number) => {
              return (
                <Box sx={{ flexGrow: 1 }} className="item-codigo" key={index}>
                  <Grid container spacing={2} columns={16} justifyContent="start" alignItems="center">
                    <Grid item xs={12}>
                      <h3 style={{ margin: "0" }}>{codeItem}</h3>
                    </Grid>
                    <Grid item xs={4}>
                      <IconButton
                        onClick={() => {
                          handleRemoveCodeBoat(index)
                        }}
                        sx={{ p: "10px" }}
                        aria-label=""
                        disableFocusRipple={true}
                        style={{ color: "#000" }}
                        color="primary"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Box>
              )
            })
          }
        </Paper>
      </Box>
    )
  }


  const RenderCorpBodyAction = () => {
    return (
      <>
        <TabPanel value={typeCorpAction} index={0}>
          {codeCorpShipList?.length > 0 ? <RenderCorpShipList /> : <></>}
        </TabPanel>

        <TabPanel value={typeCorpAction} index={1}>
          {codeCorpBoatList?.length > 0 ? <RenderCorpBoatList /> : <></>}
        </TabPanel>

        {/* <Divider /> */}

        <Grid container spacing={1} justifyContent="end" flexWrap="nowrap" alignItems="center" textAlign="right" marginTop="1rem">
          <Grid className="container-client" item xs={12} >
            <Button
              variant="contained"
              className="btn-primary rounded-button"
              color="primary"
              style={{ borderRadius: "20px" }}
              onClick={(e) => {
                e.preventDefault();
                search("codeList");
              }}
              disabled={loadingQrList}
            >
              <Loading loading={loadingQrList}></Loading>
              <strong> OK </strong>
            </Button>
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <div className="tab-one-container">
      <Alert ref={AlertRef} />
      {
        store?.userAppCorp && !store.openCreateWarrantyRequest ? (
          <RenderCorpAction />
        ) : <></>
      }
      <div className="input-container">
        <Paper
          component="form"
          className="form-input-custom"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <InputBase
            className="uppercase"
            sx={{ ml: 1, flex: 1 }}
            placeholder={i18n.BATTERY_CODE}
            inputProps={{ "aria-label": `${i18n.BATTERY_CODE}` }}
            value={searchCode}
            onChange={(event) => {
              setCode(event.target.value.toLocaleUpperCase())
            }}
            onSubmit={(e) => {
              e.preventDefault();
              search("code");
            }}
          />
          <IconButton
            onClick={scanToggle}
            sx={{ p: "10px" }}
            aria-label="Leer código QR"
            disableFocusRipple={true}
            disabled={loading}
          >
            {/* <QrCodeScannerIcon /> */}
            <IconQr />
          </IconButton>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton
            color="primary"
            sx={{ p: "10px" }}
            aria-label="Buscar código"
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              search("code");
            }}
          >
            <Loading loading={loading}>
              {/* <SearchIcon /> */}
              <IconSearch />
            </Loading>
          </IconButton>
          {
            store?.userAppCorp && !store.openCreateWarrantyRequest ? (<Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />) : <></>
          }
          {
            store?.userAppCorp && !store.openCreateWarrantyRequest ? (
              <IconButton
                onClick={addCodeBattery}
                sx={{ p: "10px" }}
                aria-label="Agregar Código"
                disableFocusRipple={true}
                style={{ color: "#000" }}
              >
                <Loading loading={loadingCodeCorp}>
                  <AddIcon />
                </Loading>

              </IconButton>
            ) : <></>
          }

        </Paper>
      </div>
      {!!scan_qr && (
        <div className="scanner-container">
          <Scanner
            callback={(res: any) => {
              let code = res;
              if (res.length > 6 && res.indexOf("=") !== -1) {
                code = res.split("=")[1];
              }

              if( code !== '' ){
                setScan(false);
              }

              findById(code);
            }}
          />
        </div>
      )}

      {/* {props?.type === "SC" ? (
        <></>
      ) : (
        <>
          <div className="input-container">
            <Paper
              component="form"
              className="form-input-custom"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <InputBase
                className="uppercase"
                onChange={(event) => setPlate(event.target.value)}
                sx={{ ml: 1, flex: 1 }}
                placeholder="Placa"
                inputProps={{ "aria-label": "Placa" }}
              />
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                color="primary"
                sx={{ p: "10px" }}
                aria-label="Buscar código"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  search("plate");
                }}
              >
                <Loading loading={loadingPlate}>
                  { <SearchIcon /> }
                  <IconSearch />
                </Loading>
              </IconButton>
            </Paper>
          </div>
          <div className="input-container">
            <Paper
              component="form"
              className="form-input-custom"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <InputBase
                className="uppercase"
                onChange={(event) => setDoc(event.target.value)}
                sx={{ ml: 1, flex: 1 }}
                placeholder={placeHolderSearch}
                inputProps={{ "aria-label": `${placeHolderSearch}` }}
              // type="number"
              />
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                color="primary"
                sx={{ p: "10px" }}
                aria-label="Buscar código"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  search("document");
                }}
              >
                <Loading loading={loadingDoc}>
                  { <SearchIcon /> }
                  <IconSearch />
                </Loading>
              </IconButton>
            </Paper>
          </div>
        </>
      )} */}

      {store?.userAppCorp && !store.openCreateWarrantyRequest ? (<RenderCorpBodyAction />) : <></>}

      <BatteryListModal
        open={showBatteryList}
        handleClose={() => {
          setShowBatteryList(false);
        }}
        batteryList={batteryList}
        callback={setBattery}
        // type={store?.user?.Country == "PE" ? "NAC" : store?.user?.Country}
        type={store?.user?.Country}
      />

      {store.active_dialog === ACTIVE_DIALOG && (
        <Dialog
          closeCallback={open}
          title={<RenderTitle />}
        // right={<RenderIcon />}
        >
          <SwitchView />
          <Bottom />
        </Dialog>
      )}
    </div>
  );
};
