import React, { useEffect } from 'react';
import QrReader from 'react-qr-reader';

interface ScannerProps {
  callback: Function
}

export const Scanner = React.memo((props: ScannerProps) => {
  const [resultQr, setResultQr] = React.useState('');
  const [scanEnabled, setScanEnabled] = React.useState(true);

  const handleError = (error: any) => {
    console.log('===============================error==================================');
    console.log(error);
    console.log('===============================error=================================');
  };

  const handleScan = React.useCallback((result: string | null) => {
    if (!!result && scanEnabled === true) {
      setScanEnabled(false);
      setResultQr(result);
      props?.callback(result);
    }
  }, [props, scanEnabled]);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const videoConstraints = {
    facingMode: isMobile ? { exact: 'environment' } : 'user'
  };

  return (
    <div className="qr-reader-wrapper">
      <QrReader
        className="qr-reader"
        videoId="video-qr-reader"
        delay={500}
        // scanDelay={500}
        onError={handleError}
        onScan={handleScan}
        // onResult={handleScan}
        constraints={videoConstraints}
      />
      <p>{resultQr}</p>
    </div>
  );
});
